.thank-you-modal {
  justify-content: center;

  .modal-content {
    max-width: 696px;
  }

  .thank-you-msg {
    margin-top: 20px;

    .text-uppercase {
      font-family: $font-secondary;
      margin-bottom: 50px;
    }
  }
}