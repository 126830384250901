.custom-chk {
  @extend %fontsize14;
  @include user-select();
  @include inline-block();
  position: relative;
  padding-left: 35px;
  cursor: pointer;

  p {
    margin-bottom: 0;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~.checkmark {
        &::after {
          display: block;
        }
      }
    }
  }

  &.tags-item {
    padding-left: 0;

    input {
      &:checked {
        ~p {
          color: $white;
          background-color: $primary-blue-color;
          border-color: $primary-blue-color;
        }
      }
    }

    p {
      @extend %fontsize12;
      padding: 2px 15px;
      color: #797986;
      border: 1px solid $brand-color-dark-gray;
      border-radius: 20px;
      letter-spacing: 0.4px;

      &.error {
        border-color: $primary-red-color;
      }
    }
  }
}

.checkmark {
  background-color: $brand-color-gray;
  border-radius: 8px;
  position: absolute;
  top: -3px;
  left: 0;
  height: 25px;
  width: 25px;

  &::after {
    @include rotate(45deg);
    content: '';
    position: absolute;
    display: none;
    left: 9px;
    top: 4px;
    width: 8px;
    height: 14px;
    border: solid $primary-blue-color;
    border-width: 0 2px 2px 0;
  }
}