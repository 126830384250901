.wir_title {
  text-transform: uppercase;
}

h1{
  line-height: 100%;
}

.wide-title {
  width:100% !important;
}

.reverse {
  flex-direction: row-reverse
}

.job-teaser__wrapper {
  display: flex;
  justify-content: flex-start;
  overflow-x: scroll;
  gap: 15px;
  padding: 0 0 20px;
}

.job-teaser__more {
  display: flex;
  justify-content: flex-end;
  padding: 0 0 40px;
}

.job-teaser__ticket {
  position: relative;
  width: 335px;
  min-width: 335px;
  min-height: 225px;
  overflow: hidden;
  background-color: $brand-color-gray;
  display: flex;

  & > span {
    position: absolute;
    width: 30px;
    height: 30px;
    border: 2px solid $border-color;
    border-radius: 50%;
    background-color: $white;
    &:nth-child(1) {
      top: -15px;
      left: -15px;
    }
    &:nth-child(2) {
      top: -15px;
      right: -15px;
    }
    &:nth-child(3) {
      bottom: -15px;
      right: -15px;
    }
    &:nth-child(4) {
      bottom: -15px;
      left: -15px;
    }
  }

  &-info {
    width: 100%;
    max-width: 285px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-right: 3px dashed $white;
    border-left: 2px solid $border-color;
    border-top: 2px solid $border-color;
    border-bottom: 2px solid $border-color;

    h4 {
      font-size: 22px;
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
    }

    &__event {
      font-weight: bold;
      margin-bottom:auto;
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        width: 100%;
      }
    }

    &__date {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      color: $brand-color-dark-gray;

      span {
        margin-top: auto;
      }
    }
  }

  &-button {
    border-left: 3px dashed $white;
    border-right: 2px solid $border-color;
    border-top: 2px solid $border-color;
    border-bottom: 2px solid $border-color;
    display: flex;
    width: 50px;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: $primary-color;
    cursor: pointer;
    @include transition(all, 0.3s, ease, 0s);

    &:hover {
      background: $primary-blue-color;
    }
  }
}

@media screen and (max-width: 425px) {
  .job-teaser__ticket {
    width: 305px;
    min-width: 305px;
    &-info {
      max-width: 255px;
      padding: 20px 15px 20px 20px;
    }
  }
}

@media screen and (max-width: 600px)  {
  h1, h2 {
    font-size: 24px;
  }
}

