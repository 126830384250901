/*******************************************************************************
* Employee Registration
*******************************************************************************/
.employee-registration__profile {
  padding: 15px;
  background-color: #fff;
  border-radius: 2px;
  text-align: center;
}

.employee-registration__profile__wrap {
  .form-action-wrapper {
    justify-content: space-between;
    margin-top: 5px;
    gap: 15px;

    .btn,
    .btn+.btn {
      margin: 10px 0;
      flex: 1 0;
    }
  }

  .position-relative {
    &.employee-profile-image--big {
      &:hover {
        cursor: pointer;
      }

      img {
        max-width: 100%;
      }
    }

    &:not(.employee-profile-image--big) {
      // height: 60px;
      width: 100%;
      padding-bottom: 100%;

      img {
        @include absoluted-fullwidth;
        object-fit: cover;
        left: 50%;
        transform: translateX(-50%);
      }

      &:hover {
        cursor: pointer;
      }
    }

    .delete-image {
      display: none;
    }

    &:hover {
      .delete-image {
        cursor: pointer;
        display: block;
      }
    }
  }

  .form-group-errors {
    border: 2px solid $primary-red-color
  }

  @include media-breakpoint-up(sm) {
    max-width: 340px;
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    left: 0;
    top: 90px;
  }

}

.employee-registration__content {
  margin-bottom: 30px;

  >h3 {
    margin-bottom: 50px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 50px;
  }

  .form-group {
    margin-bottom: 30px;
  }

  .checkbox-wrapper.lang-chk {
    border-left: 0;
    padding: 0 0 10px;

    .form-label {
      width: 100%;
    }

    .form-group {
      margin-bottom: 10px;
    }
  }
}

.modal-image-thumb-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 15px 0 30px;
}

.modal-image-thumb-text {
  padding-top: 8px;
}

.modal-image-thumb {
  width: 66px;
  height: 66px;
  position: relative;
  margin-right: 20px;
  margin-bottom: 15px;
  background-color: $brand-color-gray;
  border: 2px solid white;

  .modal-image-thumb__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    cursor: pointer;
  }
}

.modal-image-thumb--active {
  border: 2px solid $primary-blue-color;
}

.modal-image-edit-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 20px;

  .form-group {
    margin-bottom: 25px !important;
  }
}

.modal-image-edit-section-cropper {
  position: relative;
  width: 300px;
  height: 300px;
  margin-right: 20px;
}

.modal-image-edit-section-small-text {
  font-size: 14px;
  font-weight: bold;
}

.modal-image-edit-section-flipped {
  transform: scaleX(-1);
}

.modal-image-rotate-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100px;
  padding-top: 15px;
  padding-bottom: 30px;

  button:hover {
    cursor: pointer;
  }
}

.employee-registration-section {
  .form-bottom {
    max-width: 660px;
    margin-left: auto;
  }
}

//General
.margin-botttom-40 {
  margin-bottom: 40px;
}

.box-wrapper--inner {
  padding-left: 0;
  position: relative;
  min-height: 500px;

  @include media-breakpoint-up(lg) {
    padding-left: 362px;
    min-height: 700px;
  }
}

.radio-wrapper {
  margin-bottom: 28px;

  .radio-boxes {
    display: flex;
    padding: 0 0 20px 0;

    @include media-breakpoint-up(sm) {
      padding: 15px 0;
    }

    &.form-group-errors {
      .custom-radio .checkmark {
        border-color: $primary-red-color;
      }
    }

    .form-group {
      margin: 0 20px 0 0;

      @include media-breakpoint-up(sm) {
        margin: 0 20px;
      }
    }
  }
}

.custom-radio {
  @extend %fontsize14;
  @include user-select();
  @include inline-block();
  position: relative;
  padding-left: 35px;
  cursor: pointer;

  .checkmark {
    background-color: #f4f4f5;
    border: 2px solid $brand-color-dark-gray;
    top: -3px;
    border-radius: 50%;
    width: 24px;
    height: 24px;

    &::after {
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border: 0;
      border-width: 0;
      border-radius: 50%;
      background-color: $primary-blue-color;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~.checkmark {
        border-color: $primary-blue-color;

        &::after {
          display: block;
        }
      }
    }
  }
}

.employee-registration-note {
  .employee-registration-note__icon {
    max-width: 70px;
    flex: 0 0 70px;
    margin-right: 10px;
  }

  .employee-registration-note__content {
    max-width: calc(100% - 80px);
    flex: 0 0 calc(100% - 80px);

    p:last-child {
      margin-bottom: 0;
    }
  }

  +.form-group {
    margin-bottom: 10px;
  }
}

.form-bottom {
  .checkmark {
    border: 1px solid $brand-color-dark-gray;
    background-color: $brand-color-dark-gray;
    border-radius: 4px;
    top: 0;
    height: 20px;
    width: 20px;

    &::after {
      border: 0;
      background-color: $secondary-color;
      border-radius: 50%;
      left: 4px;
      width: 10px;
      height: 10px;
    }
  }
}

.c-select__multi-values {
  margin: 0 -8px;

  .c-select__multi-value {
    display: flex;
    align-items: center;
    padding: 0 8px;
    margin-bottom: 8px;
  }

  .c-select__multi-value__label {
    background-color: $primary-blue-light-color;
    padding: 4px 10px;
    @extend %fontsize14;
  }

  .c-select__multi-value__remove {
    background-color: $primary-red-color;
    padding: 4px 5px;
  }
}

.employee-registration__profile-image-edit-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@include media-breakpoint-down(sm) {
  .modal-image-edit-section {
    flex-direction: column;
  }

  .modal-image-edit-section-cropper {
    margin-bottom: 20px;
  }
}
