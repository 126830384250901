.slick-dots {
  position: relative;
  bottom: 0;
  line-height: 1px;
  margin: 20px 0;

  li {
    width: 88px;
    height: 24px;

    button {
      height: 24px;
      padding: 10px 0;
      width: 88px;
      border-radius: 2px;

      &::before {
        background-color: $brand-color-medium-gray;
        content: '';
        display: inline-block;
        height: 4px;
        opacity: 1;
        position: relative;
        width: 100%;
      }
    }

    &.slick-active {
      button {
        &:before {
          opacity: 1;
          background-color: $brand-color-dark-gray;
        }
      }
    }
  }
}