.main-container{
  display: flex;
  flex-direction: row;
  gap:5%;
}

.checkbox-error {
  position: absolute;
}

.error-container {
  margin-top: 2%;
  float: right;
  position: relative;
  width: 82%;
}

.checkbox-container{
  display: flex;
  flex-direction: column;
}

.checkbox-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.subscription-form{
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 11%;
}

.second .form-group {
  margin-bottom: 0 !important;
}

.small .box-wrapper {
  padding: 0 !important;
  padding-bottom: 40px !important;
}

.container.small{
  padding: 35px 40px;
}

.container .radio-wrapper {
  margin-bottom:0;
}

.form-button{
  float: right;
}

.small-padding{
  padding: 40px !important;
}

.news-header{
  line-height: 100%;
  padding-left: 0 !important;
}

.header-container{
  width: 50%;
}

.second{
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 4%;
  margin-top: 5%;
  justify-content: flex-end;
}

.form-container{
  width: 50%;
}
.no-shadow{
  box-shadow: 0 30px 80px rgba(0, 0, 0, 0.0) !important;
}

.right {
  text-align: left;
  float: right;
}

.exit-popup {
  display: none;
  position: fixed;
  top: 55%;
  left: 50%;
  width:30%;
  max-width: 510px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  z-index: 9999;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease;
}

.exit-popup .radio-wrapper {
  width: 72%;
}

.exit-popup .first {
  flex-direction: column;
}

.exit-popup .container {
  padding:0;
  height: 90%;
}

.exit-popup .main-container{
  flex-direction: column;
}

.exit-popup .header-container{
  width: 100%;
  margin-bottom: 4%;
}

.exit-popup .form-container {
  width:100%;
}

.exit-popup .second {
  align-items: normal;
  flex-direction: column;
  width: 100%;
  gap: 30px;
}

.exit-popup .box-wrapper {
  padding: 0px 0px !important;
  height: 100%;
}
.exit-popup .form-group{
  margin-bottom:0;
}

.exit-popup .form-button {
  float: right;
}

.exit-popup .button-container{
  width: 100%;
  padding-bottom: 2%;
}

.exit-popup .col {
  margin-bottom:15px;
}
.exit-popup .show {
  display: flex;
  flex-direction: column;
  opacity: 1;
}

.exit-popup h2{
  font-size: 2em;
  line-height: normal;
}

.exit-popup .btn-close {
  float:right;
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.exit-popup.show {
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.3s ease;
}


@media screen and (max-width: 850px)  {

  .container .box-wrapper{
    padding: 0 0 35px !important;
  }
  .exit-popup{
    width:90%;
  }
  .main-container {
    flex-direction: column;
  }
  .header-container{
    width: 100%;
    margin-bottom: 10%;
  }
  .form-container{
    width:100%;
  }
}

.exit-popup .error-container{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.exit-popup.english .checkbox-error{
  position: static !important;
  float: right;
  width: initial;
  min-width: 76px;
  max-width: 87.45px;
}


.exit-popup.german .checkbox-error {
  position: static !important;
  width: min-content;
}
@media screen and (max-width: 600px)  {
  .container .small{
    padding: 10px 10px !important;
  }
  .main-container {
    flex-direction: column;
  }
  .first, .second {
    align-items: normal;
    flex-direction: column !important;
    gap: 20px !important;
  }
  .checkbox-error{
    width: 46%;
    font-size: 12.5px;
    position: static;
    float: right;
  }
  .error-container{
    width: 100%;
    margin-top:0;
    position: static;
  }

  .subscription-form {
    gap: 20px;
  }
  .form-container {
    padding: 0 10px;
  }
  .container.small{
    padding: 15px 0 0 0 !important;
  }
}

@media screen and (max-height: 800px) {
  .exit-popup h2{
    line-height: normal;
    font-size: 1.3em;
  }
  .exit-popup{
    top:50%;
  }
  .exit-popup .col {
    margin-bottom: 4%;
  }
  .exit-popup .form-button {
    float: right;
  }
  .exit-popup .second {
    flex-direction: column;
    width:100%;
  }
  .exit-popup .radio-wrapper {
    margin-bottom: 5px;
  }
}
