.react-datepicker {
  @extend %fontsize14;
  border: 1px solid $primary-blue-color;
  font-family: $font-primary;
  font-weight: $regular-weight;
}

.react-datepicker-popper {
  z-index: 1050;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-bottom-color: $white;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  border-top-color: $primary-blue-color;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: $primary-blue-color;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  @extend %fontsize18;
  padding: 20px;
  font-family: $font-primary;
  font-weight: $regular-weight;
  text-transform: uppercase;
}

.react-datepicker__header__dropdown {
  position: absolute;
  top: 19px;
  width: 100%;
  text-align: center;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  @extend %fontsize16;
  border: 0;
  padding: 0 10px;
  font-family: $font-primary;
  font-weight: $regular-weight;
  text-transform: uppercase;

  &:focus {
    border: 0;
    outline: none;
  }
}

.react-datepicker__current-month {
  opacity: 0;
}

.react-datepicker__day,
.react-datepicker__time-name {
  @extend %fontsize18;
  width: 40px;
  line-height: 40px;
  margin: 0;
}

.react-datepicker__day--disabled {
  cursor: not-allowed;
}

.react-datepicker__day-name {
  width: 40px;
  line-height: 30px;
  color: $brand-color-dark-gray;
  font-size: 15px;
  margin: 0;
}

.react-datepicker__header {
  background-color: transparent;
  border-bottom: 0;
  padding: 0;
}

.react-datepicker__day-names {
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 0;
}

.react-datepicker__navigation {
  top: 13px;
}

.react-datepicker__navigation-icon--previous::before,
.react-datepicker__navigation-icon--next::before {
  border-color: #71717a;
}

.react-datepicker__day--outside-month {
  font-family: $font-montserrat;
  color: #d8d8d8;
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__day {
  border-radius: 0;

  &.react-datepicker__day--selected,
  &.react-datepicker__day--keyboard-selected {
    background-color: $primary-blue-color;
  }
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  &:hover {
    border-radius: 0;
  }
}

.react-datepicker__input-container {
  position: relative;

  &::before {
    content: '';
    background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTggMjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzcxNzE3QSIgZmlsbC1ydWxlPSJldmVub2RkIiBvcGFjaXR5PSIuNyI+PHBhdGggZD0iTTE2LjU2NSAxNy4zNTdjLS4wMDQuODM0LS41NDIuOTcyLS44NTYuOThMMi4zMyAxOC4zNGMtLjI1MiAwLS40NTEtLjA3Mi0uNTk0LS4yMTUtLjIwNS0uMjEtLjMxLS42LS4zMS0xLjEyNVY4LjE2SDE2LjU3YzAgMy4zMDcuMDA1IDguMDI5LS4wMDUgOS4xOTd6bTEuNDMyLTEyLjUzYy4wMDUtLjIwMS4wMDUtMS4xMS0uNjE0LTEuODEtLjI5NS0uMzMzLS44MzctLjczNC0xLjc5My0uNzY3aC0xLjQ1NVYxLjEzMUEuNzIyLjcyMiAwIDAwMTMuNDIyLjRhLjcyMi43MjIgMCAwMC0uNzE0LjczMXYxLjEySDUuMThWMS4xM0EuNzI1LjcyNSAwIDAwNC40NjYuNGEuNzIyLjcyMiAwIDAwLS43MTMuNzMxdjEuMTJIMi4zNzhDMS4yMjggMi4yNTYtLjAwNSAyLjkzMiAwIDQuODFjMCAuMTIuMDA1IDExLjQ0NSAwIDEyLjE4NSAwIC45NC4yNDMgMS42NjcuNzI4IDIuMTYzLjQxMy40Mi45NjUuNjQzIDEuNTk4LjY0M2guMDA0bDEzLjM3NC0uMDAzaC4wMDVjLjkxMyAwIDIuMjc0LS42NDcgMi4yODMtMi40MzIuMDE0LTEuODA0LjAwNS0xMi4wMzYuMDA1LTEyLjU0eiIvPjxwYXRoIGQ9Ik0zLjYgMTAuMTAxSDZ2Mi40MjVIMy42em00LjIgMGgyLjR2Mi40MjVINy44em00LjIgMGgyLjR2Mi40MjVIMTJ6bS04LjQgMy42MzhINnYyLjQyNUgzLjZ6bTQuMiAwaDIuNHYyLjQyNUg3Ljh6bTQuMiAwaDIuNHYyLjQyNUgxMnoiLz48L2c+PC9zdmc+");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 18px;
    height: 20px;
    position: absolute;
    left: 14px;
    top: 15px;
  }

  .form-control {
    padding-left: 40px;
  }
}

.time-picker-group {
  .react-datepicker__input-container {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='19px' height='20px' viewBox='0 0 19 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' opacity='0.7'%3E%3Cg transform='translate(-1488.000000, -470.000000)'%3E%3Cg transform='translate(646.000000, 300.000000)'%3E%3Cg transform='translate(64.000000, 50.000000)'%3E%3Cg transform='translate(765.000000, 64.000000)'%3E%3Cg transform='translate(0.000000, 41.000000)'%3E%3Cg transform='translate(14.000000, 15.600000)'%3E%3Cpath d='M7.81433225,11 L11.1856678,11 C11.6362649,11 12,10.6202429 12,10.1497976 C12,9.67935223 11.6362649,9.29959514 11.1856678,9.29959514 L8.6286645,9.29959514 L8.6286645,4.85020243 C8.6286645,4.37975709 8.26492942,4 7.81433225,4 C7.36373507,4 7,4.37975709 7,4.85020243 L7,10.1497976 C7,10.6202429 7.36373507,11 7.81433225,11' id='9F' fill='%2371717A'%3E%3C/path%3E%3Ccircle stroke='%2371717A' stroke-width='2' cx='8.5' cy='9.5' r='8.5'%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      top: 14px;
      left: 14px;
    }
  }
}

.react-datepicker__time-container,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: auto;
  min-width: 85px;
}

.react-datepicker-time__header {
  font-weight: $bold-weight;
  padding: 15px 25px !important;
  font-size: 14px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $brand-color-dark-gray;
    border: 3px solid $brand-color-gray;
    border-radius: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: $brand-color-gray;
    border-left: 1px solid $brand-color-gray;
  }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: $primary-blue-color;
}

@include media-breakpoint-up(lg) {
  .react-datepicker__navigation {
    top: 25px;
  }

  .react-datepicker__header__dropdown {
    top: 28px;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    padding: 29px;
  }

  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 60px;
    line-height: 60px;
  }


  .react-datepicker__day-name {
    width: 60px;
    line-height: 35px;
    font-size: 15px;
  }
}