.modal {
  padding-left: 0 !important;

  .lead {
    @extend %fontsize20;
  }

  &.modal-small {
    .modal-body {
      padding-bottom: 30px;
    }

    .modal-content {
      min-height: auto;
    }
  }
}

.modal-title {
  color: $secondary-color;
  font-family: $font-primary;
  margin-right: 15px;
}

.modal-header,
.modal-footer,
.modal-body {
  border: 0;
  padding: 0;
}

.modal-header {
  .btn-close {
    background-size: 18px;
    height: 18px;
    position: relative;
    right: -10px;
    width: 18px;
    opacity: 1;

    &:hover {
      opacity: 0.6;
    }
  }

  .modal-title {
    h3 {
      margin-bottom: 0;
    }
  }
}

.modal-body {
  padding-top: 25px;

  .modal-body-title {
    @extend %fontsize21;
    max-width: 90%;
  }

  .form-bottom {
    .buttonGroup {
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}

.modal-content {
  border: 0;
  border-radius: 4px;
  box-shadow: 0 30px 90px rgba(0, 0, 0, 0.1);
  outline: 0;
  padding: 30px;
}

.modal-backdrop {
  background-color: $popup-bg;

  &.show {
    opacity: 0.9;
  }
}

.contract-modal {
  &.modal.show {
    .modal-dialog {
      max-width: 1425px;
      padding: 15px;
    }
  }
}

.contract-details {
  min-height: 365px;
  max-height: 39vh;
  overflow-y: auto;
  border: 1px solid $brand-color-medium-gray;
  padding: 20px;
  margin-bottom: 30px;
  max-width: 904px;

  & *{
      page-break-before: auto !important;
      page-break-after: auto !important;
      page-break-inside: avoid !important;
      break-inside: avoid !important;
    }

  h3 {
    @extend %fontsize16;
    font-family: $font-primary;
    font-weight: $regular-weight;
    text-transform: capitalize;
    color: $secondary-color;
    // margin-bottom: 20px;
  }

  p {
    @extend %fontsize14;
  }

  &::-webkit-scrollbar {
    width: 27px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $brand-color-dark-gray;
    border: 7px solid $brand-color-gray;
    border-radius: 20px;
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: $brand-color-gray;
    border-left: 1px solid $brand-color-gray;
  }
}

.image-upload-modal-content > div.info-modal > div.modal-content {
  min-height: 310px;
}

@include media-breakpoint-up(lg) {
  .modal-header {
    .btn-close {
      background-size: 21px;
      height: 21px;
      right: -10px;
      width: 21px;
    }
  }

  .modal-body {
    padding-top: 50px;
  }

  .modal-content {
    padding: 48px;
    min-height: 475px;
  }

  .employee-login-modal {
    .modal-content {
      min-height: auto;
    }
  }

  .contract-details {
    padding: 30px;

    h3 {
      margin-bottom: 36px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .contract-details {
    h3 {
      margin-bottom: 35px;
    }
  }
}
