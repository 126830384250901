.form-group {
  margin-bottom: 30px;

  &.form-group-errors {
    .form-control,
    .c-select__control {
      border: 1px solid $primary-red-color;
    }

    .c-select__control:hover,
    .c-select__control--menu-is-open:hover {
      border: 1px solid $primary-red-color;
    }
  }

  .form-contract & {
    margin-bottom: 15px;

    textarea.form-control {
      min-height: 105px;
    }
  }

  &.card-number-group {
    .form-control {
      input {
        width: 35px;
        padding: 0;
        background-color: transparent;
        border: 0;
        margin-right: 5px;
        outline: none;
      }

      .input-box {
        position: relative;

        input {
          // color: #f4f4f5;
        }

        .asterisk-wrapper {
          background-color: #f4f4f5;
          position: absolute;
          left: -5px;
          // right: 0;
          // width: 100%;
          height: 100%;
          top: 0;
        }
      }
    }
  }

  .c-select__multi-value {
    background-color: hsl(0, 0%, 90%);
    border-radius: 2px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 2px;
    min-width: 0;
    box-sizing: border-box;

    .c-select__multi-value__label {
      border-radius: 2px;
      color: hsl(0, 0%, 20%);
      font-size: 85%;
      overflow: hidden;
      padding: 3px;
      padding-left: 6px;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
    }

    .c-select__multi-value__remove {
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 2px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding-left: 4px;
      padding-right: 4px;
      box-sizing: border-box;
    }
  }
}

.form-label {
  @extend %fontsize14;
  margin-bottom: 18px;
  text-transform: uppercase;
  font-weight: $semibold-weight;
  min-height: 18px;
  width: 100%;
}

.form-control {
  @extend %fontsize14;
  background-color: $brand-color-gray;
  border-color: $brand-color-gray;
  color: $secondary-color;
  height: auto;
  padding: 14px 20px;
  border-radius: 8px;

  &:focus {
    border-color: $primary-blue-color;
  }

  &::-webkit-input-placeholder {
    /* Edge */
    font-size: 14px;
    color: $brand-color-dark-gray;
    font-style: italic;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 14px;
    color: $brand-color-dark-gray;
    font-style: italic;
  }

  &::placeholder {
    font-size: 14px;
    color: $brand-color-dark-gray;
    font-style: italic;
  }

  &:focus {
    @include box-shadow(none);
    background-color: $brand-color-gray;
    outline: none;
  }

  .card-valid {
    border: 0;
    background-color: transparent;
    width: 32px;
    min-width: 1px;
    outline: none;
  }
}

textarea.form-control {
  min-height: 170px;
  resize: none;
}

.form-errors {
  @extend %fontsize14;
  color: $primary-red-color;
  margin-top: 6px;
}

.form-bottom {
  @include align-items(flex-end);
  @include flexbox;
  @include justify-content(space-between);
  @include flex-wrap(wrap);
  gap: 15px;

  &.justify-content-end {
    .btn + .btn {
      margin-left: 20px;
    }
  }
}

.form {
  .c-select__placeholder {
    font-style: italic;
  }

  .c-select__control,
  .c-select__control--menu-is-open {
    @include box-shadow(none);
    background-color: $brand-color-gray;
    border: 0;
    border-radius: 8px;

    .c-select__value-container {
      @include box-shadow(none);
      padding: 10px 0 10px 20px;
      border-radius: 8px;
      border: 0;
    }

    &:hover {
      @include box-shadow(none);
      border: 0;
    }
  }

  .c-select__control--menu-is-open {
    @include box-shadow(inset 0 0 0 1px $primary-blue-color);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:hover {
      @include box-shadow(inset 0 0 0 1px $primary-blue-color);
    }
  }

  .c-select__indicator-separator {
    display: none;
  }

  .c-select__menu {
    @include box-shadow(none);
    background-color: $brand-color-gray;
    border: 1px solid $primary-blue-color;
    border-top-color: transparent;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: hidden;
    padding: 0 7px 7px;
    margin-top: -1px;
  }

  .c-select__menu-list {
    max-height: 195px;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 10px;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #d8d8d8;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $brand-color-dark-gray;
      border-radius: 4px;
    }
  }

  .c-select__option {
    cursor: pointer;
    background-color: transparent;
    padding: 13px 10px;

    &:not(:last-child) {
      border-bottom: 1px solid $brand-color-dark-gray;
    }

    &.c-select__option--is-focused,
    &.c-select__option--is-selected {
      color: $primary-blue-color;
    }

    &:active {
      background-color: transparent;
    }
  }

  .c-select__menu-list--is-multi {
    .c-select__option {
      padding-right: 40px;
      position: relative;

      &::before {
        background-color: #d8d8d8;
        width: 20px;
        height: 20px;
        content: '';
        position: absolute;
        right: 0;
        top: 14px;
        border-radius: 4px;
      }

      &.c-select__option--is-selected {
        &::after {
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 50%;
          position: absolute;
          top: 19px;
          right: 5px;
          background-color: $secondary-color;
        }
      }
    }
  }
}

.from-text {
  font-family: $font-secondary;
  font-weight: $bold-weight;
  text-transform: uppercase;
}

.field-info {
  @extend %fontsize18;
  padding: 14px 20px 14px 0;
}

.time-fields {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='19' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(1 .6)' fill='none' fill-rule='evenodd' opacity='.7'%3E%3Cpath d='M7.814 11h3.372c.45 0 .814-.38.814-.85s-.364-.85-.814-.85H8.629V4.85c0-.47-.364-.85-.815-.85-.45 0-.814.38-.814.85v5.3c0 .47.364.85.814.85' fill='%2371717A'/%3E%3Ccircle stroke='%2371717A' stroke-width='2' cx='8.5' cy='9.5' r='8.5'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: 14px center;
  padding: 14px 20px 14px 40px;
}

.table-responsive {
  + .placeholder-text {
    font-style: italic;
  }
}

.right-align {
  text-align: right;
}

.form-wrapper {
  // margin-top: 40px;
  // padding-bottom: 30px;
  // border-bottom: 1px solid $brand-color-dark-gray;

  form {
    margin-top: 40px;
  }
}

.bonus-select.custom-select {
  z-index: 111;
}

input[readonly] {
  cursor: text;
}

button.button-unstyled {
  all: unset;
  outline: revert;
}

.ql-editor {
  position: relative;

  &.ql-blank::before {
    content: attr(data-placeholder);
    position: absolute;
    color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.2s ease;
    padding-bottom: 12px;
  }

  &:focus {
    outline: none;

    &.ql-blank::before {
      display: block;
    }
  }

  &:not(.ql-blank):focus::before {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .form-group {
    margin-bottom: 40px;

    .form-contract & {
      margin-bottom: 25px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .form-group {
    margin-bottom: 60px;

    .form-contract & {
      margin-bottom: 30px;
    }
  }

  .form-wrapper {
    form {
      margin-top: 60px;
    }
  }
}
