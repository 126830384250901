.testimonial-block {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  background-color: $primary-color;
  color: $secondary-color;
  min-height: 80vh;
  padding-left: 15px;
}

.testimonial-block__content {
  max-width: 200px;
  padding-top: 70px;
  position: absolute;
  top: 34%;

  h3 {
    color: $white;
    font-family: $font-abril;
    font-weight: $regular-weight;
    text-transform: none;
  }

  .testimonial-block__name {
    font-weight: $bold-weight;
    opacity: 0.5;

    span {
      display: block;
    }
  }

  &::before {
    content: '';
    background-image: url("data:image/svg+xml;data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNjYgNTUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTMuMjM1IDU1YzguMTk2LTIuOTg0IDE0Ljg4My03LjYyMSAyMC4wNi0xMy45MSA1LjE3Ni02LjI4OSA3Ljc2NC0xMy44MDMgNy43NjQtMjIuNTQzIDAtNS4zMy0xLjU2NC05Ljc1My00LjY5MS0xMy4yN0MyMy4yNCAxLjc1OCAxOS4wODggMCAxMy45MTIgMCAxMC4yNDUgMCA3LjAxIDEuMiA0LjIwNiAzLjU5NyAxLjQwMiA1Ljk5NiAwIDkuMzI3IDAgMTMuNTljMCAzLjYyNCAxLjE4NiA2LjQ0OSAzLjU1OSA4LjQ3NCAyLjM3MiAyLjAyNSA1LjAxNSAzLjAzOCA3LjkyNiAzLjAzOCAxLjcyNiAwIDMuNTYtLjQ4IDUuNS0xLjQ0IDEuNzI2LS44NTIgMy4wMi0xLjI3OCAzLjg4My0xLjI3OC45NyAwIDEuNjcxLjI5MyAyLjEwMy44OC40MzEuNTg1LjY0NyAxLjI1Mi42NDcgMS45OTggMCA1LjExNi0yLjEwMyA5Ljk2Ni02LjMxIDE0LjU0OS00LjIwNSA0LjU4My05LjY1MSA4LjE1NC0xNi4zMzcgMTAuNzEyTDMuMjM1IDU1em0zNC45NDEgMGM4LjE5Ny0yLjk4NCAxNC44ODMtNy42MjEgMjAuMDYtMTMuOTFDNjMuNDExIDM0LjgwMSA2NiAyNy4yODcgNjYgMTguNTQ3YzAtNS4zMy0xLjU2NC05Ljc1My00LjY5MS0xMy4yN0M1OC4xOCAxLjc1OCA1NC4wMjkgMCA0OC44NTMgMGMtMy42NjcgMC02LjkwMiAxLjItOS43MDYgMy41OTctMi44MDQgMi4zOTktNC4yMDYgNS43My00LjIwNiA5Ljk5MyAwIDMuNjI0IDEuMTg2IDYuNDQ5IDMuNTU5IDguNDc0IDIuMzczIDIuMDI1IDUuMDE1IDMuMDM4IDcuOTI2IDMuMDM4IDEuNzI2IDAgMy41Ni0uNDggNS41LTEuNDQgMS43MjYtLjg1MiAzLjAyLTEuMjc4IDMuODgzLTEuMjc4Ljk3IDAgMS42NzEuMjkzIDIuMTAzLjg4LjQzMS41ODUuNjQ3IDEuMjUyLjY0NyAxLjk5OCAwIDUuMTE2LTIuMTAzIDkuOTY2LTYuMzA5IDE0LjU0OS00LjIwNiA0LjU4My05LjY1MiA4LjE1NC0xNi4zMzggMTAuNzEyTDM4LjE3NiA1NXoiLz48L3N2Zz4=");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 55px 66px;
    height: 55px;
    width: 66px;
    position: absolute;
    top: 0;
  }
}

.testimonial-block__image {
  @include align-self(flex-end);
  max-width: 220px;
  margin-left: auto;
}

@include media-breakpoint-up(md) {
  .testimonial-block__image {
    max-width: 55%;
  }

  .testimonial-block {
    min-height: 80vh;
    padding-left: 48px;
  }
}

@include media-breakpoint-up(lg) {
  .testimonial-block__image {
    max-width: 77%;
  }

  .testimonial-block {
    min-height: 100vh;
  }
}