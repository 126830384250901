.offcanvas {
  padding: 20px 0 15px;

  @include media-breakpoint-up(md) {
    padding: 30px 0 20px;
  }

  @include media-breakpoint-up(xl) {
    padding: 50px 0 30px;
  }
}

.offcanvas-title.h5 {
  @extend %fontsize24;
  color: $brand-color-dark-gray;
  font-family: $font-secondary;
}

.offcanvas-end {
  @include box-shadow(0 0 30px 0 rgba(0, 0, 0, 0.20));
  border-left: 0;
}

.booking-list-wrapper {
  .btn {
    @extend %fontsize16;
    letter-spacing: 3.68px;
    line-height: 28px;
  }
}

.offcanvas-header {
  padding: 18px 18px 0 18px;

  .btn-close {
    width: 20px;
    height: 20px;
    padding: 0;
  }

  @include media-breakpoint-up(md) {
    padding: 12px 30px 0 18px;
  }

  @include media-breakpoint-up(xl) {
    padding: 12px 44px 0 18px;
  }
}

.offcanvas-body {
  padding: 15px 18px;

  .staff-booking-list {
    margin-top: 10px;

    >.d-flex {
      padding-bottom: 20px;
      border-bottom: 1px solid $border-color;
      margin-bottom: 30px;

    }

    .profile-text {
      font-weight: $semibold-weight;
      text-transform: capitalize;
      color: $secondary-color;
      padding-left: 22px;
    }

    .profile-wrap {
      width: 50px;
      height: 50px;

      .profile-image {
        width: 50px;
        height: 50px;

        &.active {
          border: 3px solid $primary-color;
        }
      }

      .book-time {
        height: 23px;
        min-width: 23px;
        font-size: 9px;
        padding-top: 1px;
      }
    }

    .field-action-icon {
      padding-right: 0;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 18px 30px 18px 18px;
  }

  @include media-breakpoint-up(xl) {
    padding: 20px 44px 18px 18px;
  }
}