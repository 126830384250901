.page-header {
  @include transition(all, 0.3s, ease-in-out, 0s);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1040;
  padding-bottom: 10px;

  &.page-header--sticky {
    @include box-shadow(0 0 10px rgba(0, 0, 0, 0.2));
    background-color: $white;

    .brand-logo {
      margin-top: 10px;

      a {
        max-width: 80px;
      }
    }

    .help-btn-link {
      color: $primary-color;

      &:hover,
      &:focus {
        color: $secondary-color;
      }
    }
  }
}

.brand-logo {
  @include transition(all, 0.3s, ease-in-out, 0s);
  margin-top: 15px;
  position: relative;
  z-index: 9;
  overflow: hidden;

  a {
    @include inline-flexbox();
    @include align-items(center);
    @include justify-content(center);
    color: $white;
    font-size: 15px;
    font-weight: $extra-bold-weight;
    max-width: 95px;
  }
}

.menu-trigger {
  width: 25px;
  margin-right: 20px;
  height: 25px;
  margin-top: 25px;

  .bar {
    @include transition(all, 0.3s, ease-in-out, 0s);
    width: 100%;
    height: 5px;
    margin-bottom: 4px;
    background-color: $secondary-color;
  }
}

.main-header {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  @include flex-wrap(wrap);
  padding-left: 15px;
}

.navigation {
  @include transition(all, 0.3s, ease-in-out, 0s);
  background-color: $white;
  height: 100vh;
  opacity: 0;
  left: 0;
  padding: 115px 20px 20px;
  pointer-events: none;
  position: fixed;
  visibility: hidden;
  top: 0;
  width: 100%;

  nav>ul {
    margin-bottom: 0;

    >li {
      @include media-breakpoint-up(lg) {
        margin-right: 20px;
      }

      @include media-breakpoint-up(xl) {
        margin-right: 30px;
      }
    }
  }

  .header-link {
    @extend %fontsize18;
    color: $secondary-color;
    display: block;
    font-family: $font-secondary;
    font-weight: $bold-weight;
    letter-spacing: 1.06px;
    padding: 10px 0;
    text-transform: uppercase;

    &.active {
      border-top-color: $primary-blue-color;
      color: $primary-blue-color;
    }
  }
}

.header-right-links {
  @include flexbox;
  @include align-self(flex-start);
  position: relative;
  z-index: 9;

  &.without-login-links {
    @include align-items(flex-end);
    @include flex-direction(column);
  }
}

.help-btn-link {
  @include flexbox();
  justify-content: center;
  @include border-radius(0 0 2px 2px);
  background-color: $secondary-color;
  color: $primary-color;
  font-family: $font-secondary;
  font-weight: $bold-weight;
  margin-right: 10px;
  min-width: 42px;
  letter-spacing: 0.32px;
  padding: 30px 10px 15px;

  &:hover,
  &:focus {
    color: $primary-color;
    padding: 25px 10px 20px;
  }

  span {
    @include transform(rotate(-180deg));
    @include transition(all, 0.3s, ease, 0s);
    -webkit-writing-mode: tb-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: tb-rl;
  }
}

.profile-details {
  @include border-radius(0 0 0 2px);
  @include flexbox();
  @include flex-direction(row);
  @include align-items(center);
  @include justify-content(center);
  background-color: $secondary-color;
  color: $white;
  padding: 10px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &.without-login-detail {
    @include flex-direction(column);
    @include align-items(flex-start);
    @include justify-content(flex-start);
    margin-right: 0;

    &:last-child {
      margin-top: 10px;
    }
  }

  a {
    color: $white;

    &:hover {
      color: $primary-color;
    }
  }

  .profile-link {
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);

    .profile-avatar {
      @include border-radius(50%);
      width: 39px;
      height: 39px;
      margin-right: 15px;
      position: relative;
      overflow: hidden;
      background-color: $white;

      img {
        @include absoluted-fullwidth;
        object-fit: cover;
        height: inherit;
        width: inherit;
      }
    }

    .profile-content {
      @extend %fontsize12;
      max-width: calc(100% - 50px);

      span {
        display: block;
      }
    }
  }

  .arrow-link {
    @extend %fontsize18;
    background-color: transparent;
    border: 0;
    color: $white;
    padding: 0;
    padding-right: 35px;
    letter-spacing: 0.32px;
    font-family: $font-secondary;
    font-weight: $bold-weight;
    text-transform: uppercase;
    text-align: left;

    &:hover {
      color: $primary-color;
    }
  }

  .link-label {
    @extend %fontsize12;
    margin-bottom: 10px;
  }
}

.menu-open {
  overflow: hidden;

  .navigation {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  .menu-trigger {
    .bar {
      &:first-child {
        @include transform(translate(1px, 12px) rotate(45deg));
      }

      &:nth-child(2) {
        @include transform(scale(0));
      }

      &:last-child {
        @include transform(translate(1px, -6px) rotate(-45deg));
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .profile-details {
    &.without-login-detail {
      @include justify-content(flex-end);

      &:last-child {
        margin-top: 0;
        margin-left: 10px;
      }
    }
  }

  .header-right-links {
    &.without-login-links {
      @include flex-direction(row);
      @include align-items(flex-end);
    }
  }
}

@include media-breakpoint-up(lg) {
  .page-header {
    &.page-header--sticky {
      .navigation .header-link {
        line-height: 50px;
        padding: 20px 0 0;
      }

      .brand-logo {
        a {
          max-width: 100px;
        }
      }

      .help-btn-link {
        color: $secondary-color;
      }
    }
  }

  .brand-logo {
    margin-top: 32px;
    position: relative;

    a {
      font-size: 20px;
      max-width: 116px;
    }
  }

  .main-header {
    padding-left: 50px;
  }

  .profile-details {
    @include flexbox();
    @include flex-direction(column);
    @include align-items(flex-start);
    @include justify-content(flex-end);
    min-height: 98px;
    padding: 15px 45px 15px 30px;

    &:last-child {
      min-width: 242px;
    }

    .profile-link {
      @include align-items(center);
      @include flexbox();
      @include justify-content(flex-start);
      margin-bottom: 10px;

      .profile-content {
        max-width: calc(100% - 50px);

        span {
          display: block;
        }
      }
    }
  }

  .help-btn-link {
    background-color: $primary-color;
    color: $secondary-color;
    margin-right: 15px;
    padding: 45px 9px 18px;

    &:hover,
    &:focus {
      color: $secondary-color;
      padding: 40px 10px 23px;
    }
  }

  .navigation {
    @include align-self(flex-start);
    opacity: 1;
    position: relative;
    margin: 0 20px;
    background-color: transparent;
    padding: 0;
    top: auto;
    height: auto;
    visibility: visible;
    width: auto;
    pointer-events: auto;

    ul {
      @include flexbox();
      @include align-items(center);
      list-style-type: none;
    }

    .header-link {
      border-top: 8px solid transparent;
      line-height: 62px;
      padding: 42px 0 20px;

      &.active {
        border-top-color: $primary-blue-color;
        color: $primary-blue-color;
      }
    }
  }

  .header-right-links {
    align-items: normal;
  }

  .menu-open {
    overflow: auto;
  }
}
