.two-col-wrap {
  padding: 0 15px;
}

@include media-breakpoint-up(md) {
  .two-col-wrap {
    padding: 0 30px;
  }
}

@include media-breakpoint-up(xl) {
  .two-col-wrap {
    padding: 0 60px;
  }
}