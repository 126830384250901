.new-request-block {
  margin-bottom: 40px;

  h3 {
    margin-bottom: 40px;
  }

  .form-group {
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}

.new-job-block {
  h3 {
    padding-bottom: 10px;
    border-bottom: 3px solid $primary-blue-color;
  }
}

.job-list {
  margin-bottom: 40px;

  h3 {
    margin-bottom: 18px;
  }
}

.job-list__header {
  border-bottom: 3px solid $primary-blue-color;
  margin-bottom: 32px;
  padding-bottom: 10px;

  .form-group {
    margin-bottom: 30px;
  }
}

.field-action-icon {
  padding: 14px 20px;
  cursor: pointer;
}

.disable {
  pointer-events: none;
}

.numberList {
  @include inline-block;
  padding: 4px 0;
  background-color: $secondary-color;
  border-radius: 14px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
  min-width: 42px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 0;
    margin-top: 49px;
  }
}

.job-list__item {
  border-bottom: 1px solid $brand-color-dark-gray;
  margin-bottom: 32px;

  .form-group {
    margin-bottom: 30px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 40px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 20px;
    }
  }

  @include media-breakpoint-up(xl) {
    +.job-list__item {
      .form-label {
        display: none;
      }

      .form-label-end-time {
        width: auto;
        display: block;

        span {
          display: none;
        }

        svg {
          width: 0;
          opacity: 0;
        }
      }

      .time-picker-group-end {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        justify-content: flex-end;

        .react-datepicker-wrapper {
          width: calc(100% - 5px);
        }
      }

      .numberList {
        margin-top: 10px;
      }
    }

    .fields-text {
      line-height: 49px;
    }
  }
}

.form-action-wrapper {
  @include flexbox();
  margin-top: 37px;

  @include media-breakpoint-up(md) {
    @include justify-content(flex-end);
  }
}

.request-details-select {
  &.c-select__indicators {
    display: none;
  }

  &.c-select__value-container {
    padding-right: 20px !important;
  }

  &.ce-select__menu {
    @include media-breakpoint-up(md) {
      min-width: 355px;
    }
  }
}