/*******************************************************************************
* Button
*******************************************************************************/
.btn {
  @extend %fontsize16;
  @include transition(all, 0.3s, ease, 0s);
  @include inline-block();
  background-color: $primary-blue-color;
  border: 1px solid $primary-blue-color;
  border-radius: 1.47px;
  box-shadow: none;
  color: $white;
  font-family: $font-secondary;
  font-weight: $bold-weight;
  letter-spacing: 3.68px;
  line-height: 30px;
  margin: 0;
  outline: 0;
  padding: 8px 15px 9px;
  text-transform: uppercase;
  white-space: normal;

  + .btn {
    margin-left: 40px;
  }

  &:hover {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $white;
  }

  &:focus,
  &:active {
    box-shadow: none;
    color: $white;
  }

  &.btn--outline {
    background-color: transparent;
    border: 2px solid $primary-blue-color;
    color: $primary-blue-color;

    &:focus,
    &:hover {
      background-color: $primary-blue-color;
      color: $white;
    }
  }

  &.btn-red-outline {
    background-color: transparent;
    border: 2px solid $primary-red-color;
    color: $primary-red-color;

    &:focus,
    &:hover {
      background-color: $primary-red-color;
      color: $white;
    }
  }

  &.btn-primary {
    background-color: transparent;
    color: $primary-blue-color;
    border-width: 2px;
    padding: 9px 15px;

    &:hover {
      border-color: $primary-blue-color;
      background-color: $primary-blue-color;
      color: $white;
    }

    &:focus,
    &:active {
      box-shadow: none;
      border-color: $primary-blue-color;
      background-color: transparent;
      color: $primary-blue-color;
    }
  }
  &.btn--red {
    background-color: $primary-red-color;
    color: $white;
    border-color: $primary-red-color;

    &:hover {
      border-color: $primary-red-color;
      background-color: transparent;
      color: $primary-red-color;
    }

    &:focus,
    &:active {
      box-shadow: none;
      border-color: $primary-red-color;
      background-color: transparent;
      color: $primary-red-color;
    }
  }

  &.btn--text {
    background: none;
    font-size: 16px;
    color: $primary-blue-color;
    text-decoration: underline $primary-blue-color;
    padding: 0;
    border: 0;

    &:hover {
      color: $brand-color;
      text-decoration: underline $brand-color;
    }
  }
}

.startNowLoginBtn {
  margin-left: 1px !important;
}

button {
  transition: all 0.3s ease-in-out 0s;

  &:focus {
    outline: none;
  }
}

@include media-breakpoint-up(lg) {
  .btn {
    padding: 11px 25px 9px;
    text-align: center;
  }
}

.event-btn {
  &:disabled {
    background-color: $brand-color-dark-gray;
    border: 1px solid $brand-color-dark-gray;
  }
}
