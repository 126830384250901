.file-upload-btn-wrapper {
  border: 1px dashed #979797;
  border-radius: 4px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;

  .file-upload-content {
    padding: 10px 40px 18px;

    p {
      margin-bottom: 10px;

      span {
        color: $primary-blue-color;
      }
    }

    .text-bold {
      font-weight: $bold-weight;
    }
  }

  input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.file-upload-icon {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmlld0JveD0iMCAwIDc1IDc1IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNNTcuNSAxMi41YTUgNSAwIDAxNSA1djQwYTUgNSAwIDAxLTUgNWgtNDBhNSA1IDAgMDEtNS01di00MGE1IDUgMCAwMTUtNWg0MHptLTUuNjQyIDE4LjY5NEwzOC42MTYgNDguNTZhMi41IDIuNSAwIDAxLTMuNzQ0LjI2M2wtNS4wNTUtNC45OUwxOS4wMyA1Ny41SDU3LjVWMzcuMzAzbC01LjY0Mi02LjEwOXpNNTcuNSAxNy41aC00MHYzMy44NjhsMTAuMTIyLTEyLjgyNmEyLjUgMi41IDAgMDEzLjcxOS0uMjNsNS4wMjEgNC45NTYgMTMuMzI1LTE3LjQ3M2EyLjUgMi41IDAgMDEzLjgyNC0uMThsMy45ODkgNC4zMTlWMTcuNXpNMzAgMjBhNy41IDcuNSAwIDExMCAxNSA3LjUgNy41IDAgMDEwLTE1em0wIDEwYTIuNSAyLjUgMCAxMDAtNSAyLjUgMi41IDAgMDAwIDV6Ii8+PC9kZWZzPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0iTTAgMGg3NXY3NUgweiIvPjxtYXNrIGlkPSJiIiBmaWxsPSIjZmZmIj48dXNlIHhsaW5rOmhyZWY9IiNhIi8+PC9tYXNrPjx1c2UgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJub256ZXJvIiB4bGluazpocmVmPSIjYSIvPjxnIGZpbGw9IiM0MUE5QzciIG1hc2s9InVybCgjYikiPjxwYXRoIGQ9Ik0wIDBoNzV2NzVIMHoiLz48L2c+PC9nPjwvc3ZnPg==');
  height: 75px;
  width: 75px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 75px 75px;
  margin: 0 auto;
}