//****************************************************************************
//  Font sizes extensions
//***************************************************************************/
%fontsize43 {
  font-size: 32px;
  line-height: 50px;
}

%fontsize24 {
  font-size: 20px;
  line-height: 27px;
}

%fontsize21 {
  font-size: 18px;
  line-height: 24px;
}

%fontsize20 {
  font-size: 16px;
  line-height: 22px;
}

%fontsize18 {
  font-size: 15px;
  line-height: 17px;
}

%fontsize16 {
  font-size: 14px;
  line-height: 17px;
}

%fontsize14 {
  font-size: 14px;
  line-height: 19px;
}

%fontsize12 {
  font-size: 12px;
  line-height: 17px;
}

@include media-breakpoint-up(lg) {
  %fontsize20 {
    font-size: 18px;
    line-height: 23px;
  }
}

@include media-breakpoint-up(lg) {
  %fontsize43 {
    font-size: 43px;
    line-height: 62px;
  }

  %fontsize24 {
    font-size: 24px;
    line-height: 36px;
  }

  %fontsize20 {
    font-size: 20px;
    line-height: 28px;
  }

  %fontsize21 {
    font-size: 21px;
    line-height: 28px;
  }

  %fontsize18 {
    font-size: 18px;
    line-height: 20px;
  }

  %fontsize16 {
    font-size: 16px;
    line-height: 22px;
  }
}