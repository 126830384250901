/*******************************************************************************
* Jobs
*******************************************************************************/
.jobs-table {

  thead,
  tbody {

    th,
    td {
      padding: 15px;

      &.jobs-event-name {
        max-width: 220px;
        min-width: 130px;
        word-break: break-word;
      }

      &.jobs-address {
        min-width: 200px;
        max-width: 320px;
      }

      &.jobs-status {
        min-width: 110px;
      }

      &.jobs-position {
        min-width: 150px;
      }

      &.jobs-briefings-read {
        min-width: 142px;
      }
    }

    td,
    .link {
      font-weight: $semibold-weight;
    }

    .jobRow:hover {
      cursor: pointer;
      background-color: #daeff5;
      color: #41a9c7;
    }
  }

  .jobs-date {
    max-width: 140px;
  }
}