.job-wall-detail-button {
  max-width: 340px;
  margin-left: auto;
  text-align: right;
  // margin-top: 17px;
  clear: right;

  .employee-registration-note {
    margin-bottom: 30px;
    text-align: left;

    .text-uppercase {
      font-family: $font-secondary;
    }
  }
}
.buttonInfoOverlay {
  text-align: right;
  float: right;
  margin: 10px 0;
  z-index: 9999;
  .iBtnTooltip {
    display: none;
    position: relative;
    font-size: 11px;
    background-color: rgba(255, 255, 255);
    margin-bottom: -23px;
    margin-right: -1px;
    padding: 0px 8px;
    border: 1px solid;
    border-radius: 15px;
    user-select: none;
    cursor: default;
    height: 23px;
    // box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  }
  &:hover {
    .iBtnTooltip {
      display: block;
    }
  }
}
.remarksForEmployee {
  margin-bottom: 50px;
  .form-group {
    margin-bottom: 30px;
    .form-control {
      width: 50%;
    }
  }
}
