.employee-profile-image {
  width: 100%;
  height: 60px;
  background-color: #D8D8D8;
  cursor: pointer;

  &.plus-icon {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdGggaWQ9ImEiIGQ9Ik01MCAyMy4zMzNhMy4zMzMgMy4zMzMgMCAwMTMuMzMzIDMuMzM0djIwaDIwYTMuMzMzIDMuMzMzIDAgMTEwIDYuNjY2aC0yMHYyMGEzLjMzMyAzLjMzMyAwIDExLTYuNjY2IDB2LTIwaC0yMGEzLjMzMyAzLjMzMyAwIDExMC02LjY2NmgyMHYtMjBBMy4zMzMgMy4zMzMgMCAwMTUwIDIzLjMzM3oiLz48L2RlZnM+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cmVjdCB3aWR0aD0iOTMuMzMzIiBoZWlnaHQ9IjkzLjMzMyIgeD0iMy4zMzMiIHk9IjMuMzMzIiBzdHJva2U9IiNGRkYiIHN0cm9rZS13aWR0aD0iNi42NjciIHJ4PSI0Ni42NjciLz48bWFzayBpZD0iYiIgZmlsbD0iI2ZmZiI+PHVzZSB4bGluazpocmVmPSIjYSIvPjwvbWFzaz48dXNlIGZpbGw9IiMwMDAiIGZpbGwtcnVsZT0ibm9uemVybyIgeGxpbms6aHJlZj0iI2EiLz48ZyBmaWxsPSIjRkZGIiBtYXNrPSJ1cmwoI2IpIj48cGF0aCBkPSJNMCAwaDEwMHYxMDBIMHoiLz48L2c+PC9nPjwvc3ZnPg==');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20px;
  }

  &.employee-profile-image--big {
    width: 100%;
    height: 300px;

    &.plus-icon {
      background-size: 100px;
    }
  }

  .upload-image-info-text {
    color: #000000;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
    pointer-events: none;
    padding-bottom: 15px;
    text-transform: uppercase;
  }
}

.images-info {
  text-align: right;
}
