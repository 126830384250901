//****************************************************************************
//  Include a custom build of the Twitter Bootstrap SCSS files.
//***************************************************************************/
@import '~bootstrap/scss/bootstrap';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '../assets/fonts/open_sans/stylesheet.scss';
@import '../assets/fonts/Sans_Serif/stylesheet.scss';
// @import "../assets/fonts/Open_Sans_Condensed/stylesheet.scss";
@import '../assets/fonts/abril_fatface/stylesheet.scss';
@import '../assets/fonts/Montserrat/stylesheet.scss';
@import 'react-datepicker/dist/react-datepicker.css';

//****************************************************************************
//  Include our own variables and the ones that override the Bootstrap variables
//  Always include this file first
//***************************************************************************/
@import 'base/variables';

//****************************************************************************
//  Include base styles
//***************************************************************************/
@import 'mixins/mixins';
@import 'base/extensions';
@import 'base/typography';

//****************************************************************************
//  Include components styles
//***************************************************************************/
@import 'components/demo';
@import 'components/header';
@import 'components/form';
@import 'components/modal';
@import 'components/button';
@import 'components/newsletter';
@import 'components/testimonial-slider';
@import 'components/slider';
@import 'components/two-col-wrap';
@import 'components/text-image-teaser';
@import 'components/footer';
@import 'components/general';
@import 'components/date-picker';
@import 'components/box-wrapper';
@import 'components/home';
@import 'components/form-add-job';
@import 'components/full-width-teaser';
@import 'components/table';
@import 'components/add-request';
@import 'components/load-more';
@import 'components/loader';
@import 'components/profile';
@import 'components/new-request';
@import 'components/popver-default';
@import 'components/file-upload';
@import 'components/request-overview';
@import 'components/staff-modal';
@import 'components/custom-checkbox';
@import 'components/jobs';
@import 'components/custom-checkbox';
@import 'components/request-payment';
@import 'components/job-detail';
@import 'components/employee-registration';
@import 'components/employee-profile-image';
@import 'components/tabs';
@import 'components/alert';
@import 'components/audio-recorder';
@import 'components/form-confirm-hours';
@import 'components/croppie';
@import 'components/cart-sidebar';
@import 'components/thank-you-modal';
@import 'components/delete-voice-modal';
@import 'components/contact';
@import 'components/add-briefing-modal';
@import 'components/employee-details-show__content';
@import 'components/job-wall-detail';
@import 'components/brief-popover';
@import 'components/video';
@import 'components/employee-images-modal';
@import 'components/workwithus';
@import 'components/event-fee-modal';
