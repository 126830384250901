.placeholder-text {
  @extend %fontsize14;
  color: $text-muted-color;
  margin: 0 20px;
}

/**/
.no-wrap-cls {
  white-space: nowrap;
}

/**/

.discount-text {
  margin-right: 20px;
}

.headline-with-edit {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  position: relative;
  padding-right: 30px;

  .edit-icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 29px;
    height: 36px;
  }
}

.billing-details {
  margin-top: 150px;
  margin-bottom: 50px;

  .headline-with-edit {
    margin-bottom: 50px;
  }
}

.payment-detail-wrapper {
  margin-bottom: 60px;
  border-bottom: 1px solid $secondary-color;
}

.payment-promo {
  border-bottom: 1px solid $brand-color-medium-gray;
  margin-top: 40px;
  margin-bottom: 40px;
}

.payment-method-options {
  @include flexbox();
  @include align-items(center);
  margin-top: 30px;
  margin-bottom: 70px;

  .payment-method-option {
    @include inline-flexbox;
    @include align-items(center);
    @include justify-content(center);
    border: 1px solid $brand-color-medium-gray;
    border-radius: 8px;
    width: 101px;
    height: 62px;
    cursor: pointer;
    padding: 15px 8px;

    &:not(:last-child) {
      margin-right: 28px;
    }

    &.active {
      border-width: 5px;
      border-color: $primary-blue-color;
    }
  }
}

.card-element {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 5px;
  background-color: white;
}