.contact-wrapper {
  .contact-title {
    margin-bottom: 50px;
  }
}

.form-contact {
  max-width: 586px;

  .form-group {
    margin-bottom: 30px;

    .recaptcha {
      transform: scale(0.9);
      transform-origin: 0 0;
    }
  }
}