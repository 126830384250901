.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #DDF4E9;
  border-color: #9DE1C0;
  color: rgba($secondary-color, 0.7);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: #FEE0E0;
  border-color: #F2CCCC;
  color: rgba($secondary-color, 0.7);
  white-space: pre-wrap;
}

.Toastify__close-button {
  color: rgba($secondary-color, 0.7);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background-color: rgba($secondary-color, 0.1);
}
