.full-width__teaser-wrap {
  margin: 120px 0;
}

.full-width__image-wrapper {
  margin-bottom: 20px;
}

.half-width__teaser-wrap {
  margin: 60px 0;

  img {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  .btn {
    margin-top: 10px;

    @include media-breakpoint-up(lg) {
      margin-top: 20px;
    }
  }

  @include media-breakpoint-up(lg) {
    margin: 120px 0;
  }
}
