.brief-overlay {
    position: relative;

    &::before {
        content: '';
        position: fixed;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 9999;
    }

    .read-text {
        position: relative;
        z-index: 9999;
    }   
}

.read-text {
    cursor: pointer;
    text-align: right;
    color: $primary-blue-color;
    text-decoration: underline;

    &:hover {
        color: $primary-color;
        
        &::selection {
            color: $primary-color;
        }
    }

    &::selection {
        background-color: transparent;
        color: $primary-blue-color;
    }
}

.brief-popover {
    z-index: 99999;
    min-width: 280px;

    h4 {
        color: $brand-color-dark-gray;
        margin-bottom: 30px;
    }

    .brief-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        li:not(:last-child) {
            margin-right: 7px;
        }

        li {
            span {
                display: inline-block;
                cursor: pointer;

                &::selection {
                    background-color: transparent;
                    color: $secondary-color;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        min-width: 383px;
    }
}

.brief-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    h4 {
        margin-bottom: 0;
    }

    .btn-close {
        opacity: 1;
    }
}