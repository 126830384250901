.load-more {
  margin-top: 60px;
  text-align: center;

  .load-more-link {
    @include transition(all, 0.3s, ease-in-out, 0s);
    color: $primary-blue-color;
    font-weight: $bold-weight;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: $primary-color;
    }
  }
}