@font-face {
  font-family: 'Open Sans';
  src: url('OpenSans-Italic.eot');
  src: url('OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
    url('OpenSans-Italic.woff2') format('woff2'),
    url('OpenSans-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('OpenSans-Regular.eot');
  src: url('OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('OpenSans-Regular.woff2') format('woff2'),
    url('OpenSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('OpenSans-SemiBold.eot');
  src: url('OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('OpenSans-SemiBold.woff2') format('woff2'),
    url('OpenSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('OpenSans-Bold.eot');
  src: url('OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('OpenSans-Bold.woff2') format('woff2'),
    url('OpenSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('OpenSans-ExtraBold.eot');
  src: url('OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('OpenSans-ExtraBold.woff2') format('woff2'),
    url('OpenSans-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
