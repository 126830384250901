.homepage-section {
  padding-bottom: 40px;
}

@include media-breakpoint-up(lg) {
  .homepage-section {
    @include flexbox();
    padding-bottom: 65px;

    .testimonial-content {
      max-width: 37%;
      width: 100%;
    }

    .content-main {
      max-width: 63%;
    }
  }
}