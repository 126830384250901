.popover {
  @include box-shadow(0 30px 80px 0 rgba(0, 0, 0, 0.1));
  border: 0;
  padding: 20px;
  max-width: 200px;

  .popover-arrow {
    display: none;
  }

  .popover-body {
    padding: 0;
    color: $secondary-color;
  }

  p:last-child {
    margin-bottom: 0;
  }

  h5 {
    color: $secondary-color;
    margin-bottom: 10px;
  }

  @include media-breakpoint-up(md) {
    max-width: 250px;
  }

  &.blacksuit-image {
    @include media-breakpoint-up(md) {
      max-width: 450px;
    }
  }

  .staff-action {
    position: relative;
    padding-right: 36px;
    transition: all 0.3s ease-in-out 0s;
    
    &:hover {
      cursor: pointer;
      color: $primary-color;

      &::after {
        right: -3px;
      }
    }

    &::after {
      content: url(data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2016%2015%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%2371717A%22%20fill-rule%3D%22evenodd%22%20d%3D%22M.833%209.105h12.749l-4.714%204.626a.707.707%200%2000.99%201.008l5.93-5.82a.705.705%200%2000.005-1.004l-5.94-5.941a.704.704%200%2000-.999%200%20.706.706%200%20000%20.998l4.722%204.722H.833a.706.706%200%20000%201.411%22%2F%3E%3C%2Fsvg%3E);
      width: 16px;
      height: 15px;
      position: absolute;
      right: 0;
      top: 2px;
      transition: all 0.3s ease-in-out 0s;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid $brand-color-medium-gray;
    }
  }
}

.popover-icon {
  margin-left: 5px;
  margin-top: -3px;
  cursor: pointer;
}