.cr-slider {
  height: 10px;
  padding: 0;

  &::-webkit-slider-runnable-track {
    height: 10px;
    background-color: rgba(#41A9C7, 0.3);
    color: #41A9C7;
    border-radius: 5px;
  }

  &::-webkit-slider-thumb {
    width: 15px;
    height: 15px;
    background: #41A9C7;
    border-radius: 50%;
    position: relative;
    top: 3px;
  }
  &:active::-webkit-slider-thumb {
    background: #41A9C7;
  }
  // Firefox
  &::-moz-range-progress {
    background-color: #43e5f7;
  }
  &::-moz-range-track {
    background-color: #41A9C7;
  }
  // IE
  &::-ms-fill-lower {
    background-color: #43e5f7;
  }
  &::-ms-fill-upper {
    background-color: #41A9C7;
  }
}