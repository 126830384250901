.add-briefing-modal {
  &.modal-xxl {
    max-width: 1395px;
  }

  .modal-footer {
    padding-top: 36px;
    border-top: 1px solid $text-muted-color;
    justify-content: flex-start;

    p {
      max-width: 460px;
      margin: 0;
    }
  }

  .modal-content {
    min-height: auto;
  }

  .form-group {
    margin-bottom: 36px;
  }

  h3,
  .h3 {
    color: $brand-color-dark-gray;
  }

  .file-upload-btn-wrapper .file-upload-content {
    background-color: #FAFAFA;
    padding: 18px 20px;
    min-height: 106px;
  }

  .file-upload-btn-wrapper {
    border: 0;
    padding-right: 30px;

    .delete-btn {
      position: absolute;
      right: 0;
      bottom: 0;
      cursor: pointer;

      path {
        transition: all 0.3s ease-in 0s;
      }

      &:hover {
        path {
          fill: $primary-color;
        }
      }
    }
  }
}

.pdf-uploader {
  max-width: calc(100% - 30px);

  &:hover {
    +.file-upload-content {
      .btn-primary {
        background-color: $primary-blue-color;
        color: $white;
      }
    }
  }
}

.add-briefing-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .form-group {
    flex: 0 0 270px;
    max-width: 270px;
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
  }

  .file-upload-content {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    justify-content: flex-start;

    .form-group {
      flex: 0 0 320px;
      max-width: 320px;

      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .form-group {
      flex: 0 0 360px;
      max-width: 360px;

      &:not(:last-child) {
        margin-right: 60px;
      }
    }
  }
}