.tab-menu {
  padding-left: 0;
  margin-bottom: 30px;

  .nav-item {
    margin-right: 30px;
    margin-bottom: 15px;
    font-weight: $bold-weight;

    &:last-child {
      margin-right: 0;
    }

    &:before {
      display: none;
    }
  }

  .nav-link {
    text-decoration: underline;
    color: $primary-blue-color;
    padding: 0;
    cursor: pointer;

    &.active {
      color: $brand-color-dark-gray;
      cursor: default;
      text-decoration: none;
    }
  }

}

.tab-content {
  .c-select__control.job-select {
    min-width: 200px;
  }

  .table {
    tbody {
      td {
        font-weight: 400;

        &.events-date {
          font-weight: 700;
        }
      }
    }
  }
}