.form-confirm-hours {
  .profile-book {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid $brand-color-dark-gray;
  }

  .time-picker-group {
    max-width: 180px;
  }

  p.small-text:not(.field-info) {
    padding: 5px 0;
  }
}

.event-details-item {
  td {
    span {
      @extend %fontsize18;

      &.total-amount {
        font-weight: $bold-weight;
      }
    }
  }
}