.tab--open {
  &.parent-tab {
    background-color: $primary-blue-light-color;

    td {
      border: 0;
    }
  }

  + .sub-table-data {
    // display: none;
  }

  .active-subrow {
    + tr {
      td {
        border-bottom: 0;
      }
    }
  }
}

.row-to-toggle {
  display: none;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  &.fadeInDown {
    display: table-row;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }
}

.table {
  tr.sub-table-data {
    border-bottom: 1px solid $border-color;

    .detail-link {
      font-weight: $semibold-weight;
    }

    .text-medium {
      @extend %fontsize16;
    }

    td {
      @extend %fontsize14;
      border: 0;
      font-weight: $semibold-weight;

      span {
        max-width: 400px;
      }
    }
  }
}

.text-small {
  span {
    @extend %fontsize14;
  }
}

.weight-normal {
  font-weight: $regular-weight;
}

tr.sub-table {
  > td {
    padding: 0;
  }

  thead {
    tr {
      border-bottom: 1px solid $border-color;
    }
  }

  tbody {
    tr {
      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }
    }
  }

  table {
    background-color: $brand-color-light-gray;
  }

  .req-overview-tr td {
    // white-space: nowrap;
  }

  .numberList {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    margin: 0;
  }

  .field-action-icon {
    padding: 0;
    text-align: right;
  }

  .btn {
    font-size: 11px;
    padding: 1px 25px;
  }
}

.profile-wrap {
  @include inline-block;
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: inherit;

  .profile-image {
    position: relative;
    width: 33px;
    height: 33px;
    display: block;
    border-radius: 50%;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.size-large {
    width: 52px;
    height: 52px;

    .profile-image {
      position: relative;
      width: 52px;
      height: 52px;
      border: 2px solid $primary-blue-color;
    }

    .profile-gender {
      width: 24px;
      height: 24px;
      background-size: 24px;
    }
  }
}

.profile-gender {
  position: absolute;
  right: 0;
  top: 0;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSI+PGNpcmNsZSBjeD0iOCIgY3k9IjgiIHI9IjgiIGZpbGw9IiMwMDAiLz48ZyBzdHJva2U9IiNGRkYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIuNDYyIDEuNDYyKSI+PGNpcmNsZSBjeD0iNC43MDgiIGN5PSI3LjY0NiIgcj0iMy42Ii8+PHBhdGggZD0iTTcuNDU5IDQuOTQ3bDIuNjExLTIuNjEybS0yLjI2OC0uMTYzaDIuMzVtLjA4MiAyLjQzMWwtLjAwMS0yLjM1Ii8+PC9nPjwvZz48L3N2Zz4=');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  width: 16px;
  height: 16px;

  &.female {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSI+PGNpcmNsZSBjeD0iNy44IiBjeT0iNy44IiByPSI3LjgiIGZpbGw9IiMwMDAiLz48ZyBzdHJva2U9IiNGRkYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUuMzcgMy43NSkiPjxjaXJjbGUgY3g9IjIuNTY1IiBjeT0iMi41NjUiIHI9IjIuNTY1Ii8+PHBhdGggZD0iTTIuNTY1IDUuNzZ2Mi44OE0xLjQ4OSA3LjQyNUgzLjc4Ii8+PC9nPjwvZz48L3N2Zz4=');
  }

  &.other {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDMxMC4yODUgMzEwLjI4NSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzEwLjI4NSAzMTAuMjg1OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8cGF0aCBkPSJNMTU1LjE0MywwLjAwMUM2OS41OTcsMC4wMDEsMCw2OS41OTcsMCwxNTUuMTQzYzAsODUuNTQ1LDY5LjU5NywxNTUuMTQyLDE1NS4xNDMsMTU1LjE0MnMxNTUuMTQzLTY5LjU5NywxNTUuMTQzLTE1NS4xNDINCglDMzEwLjI4NSw2OS41OTcsMjQwLjY4OSwwLjAwMSwxNTUuMTQzLDAuMDAxeiBNMjQ0LjE0MywxNzEuNDk4YzAsNC40MTEtMy41ODksOC04LDhoLTE2M2MtNC40MTEsMC04LTMuNTg5LTgtOHYtMzINCgljMC00LjQxMSwzLjU4OS04LDgtOGgxNjNjNC40MTEsMCw4LDMuNTg5LDgsOFYxNzEuNDk4eiIvPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=');
  }
}

.profile-book {
  @include flexbox();
  @include align-items(center);

  &.link {
    .profile-text {
      color: $primary-blue-color;
    }

    &:hover {
      .profile-text {
        color: $primary-color;
      }
    }
  }
}

.profile-text {
  @extend %fontsize14;
  color: $brand-color-dark-gray;
  text-transform: uppercase;
  padding-left: 12px;
}

.info-icon {
  width: 18px;
  height: 18px;
  background-color: $primary-color;
  border-radius: 50%;
  text-align: center;
  color: $secondary-color;
}

.error-icon {
  width: 18px;
  height: 18px;
  background-color: $primary-red-color;
  border-radius: 50%;
  text-align: center;
  color: white;
}

.aplpication-wrap {
  @include flexbox();
  @include align-items(center);

  .detail-link {
    white-space: nowrap;
    padding-left: 12px;
  }
}

.last-table-row {
  span {
    margin-bottom: 120px;
  }
}

div.request-book {
  @include flexbox();
  @include align-items(center);

  .btn {
    padding: 1px 9px;
    margin-left: 11px;
  }
}

.book-wrap {
  @include inline-block;
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: inherit;
}

.book-time {
  font-size: 6px;
  width: 16px;
  height: 15px;
  background-color: $primary-red-color;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
}

.add-job-login-modal {
  .modal-title {
    h3 {
      color: $brand-color-dark-gray;
    }
  }

  .form-bottom {
    @include justify-content(flex-end);
  }

  .modal-content {
    @include media-breakpoint-up(lg) {
      min-height: auto;
    }
  }

  .modal-lg,
  .modal-xl {
    @include media-breakpoint-up(lg) {
      max-width: 1395px;
    }
  }
}

.load-more-wrapper {
  padding-top: 105px;

  .load-more-link {
    font-size: 16px;
    line-height: 22px;
    color: $primary-blue-color;
    cursor: pointer;
    text-decoration: underline;
  }
}

.add-briefings-wrapper {
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 11;
    cursor: pointer;
  }
}
