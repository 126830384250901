.table {
    .mobile-row {
        th,
        td {
            white-space: inherit;
    
            span {
                margin: 5px 0;;
            }
    
            span:not(:last-child) {
                &:after {
                    content: ',';
                    margin-right: 5px;
                }
            }
        }
    }
}