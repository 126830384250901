.event-fee-modal {
  p {
    font-size: 14px;
  }
  #payment-type-text {
    margin-bottom: 20px;
  }
  .divider {
    color: $brand-color-dark-gray;
    padding: 15px 0;
  }
  .payment-form {
    label {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: $bold-weight;
      padding-bottom: 5px;
    }
    .input-container {
      background-color: $brand-color-gray;
      padding: 12px;
      border-radius: 6px;
    }
    .number {
      width: 50%;
    }
    .second-section {
      display: flex;
      margin-top: 15px;
      .expiry {
        margin-right: 30px;
        width: 30%;
      }
      .cvc {
        width: 15%;
      }
    }
    .btn-container {
      margin-top: 50px;
      display: flex;
      justify-content: end;
    }
  }
}
