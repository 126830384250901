/**************************************************************************
* Video Iframe
***************************************************************************/
.video-iframe-wrapper {
  position: relative;
  // padding-top: 56.3%;
  margin-bottom: 30px;
  background-color: $primary-color;

  iframe {
    @include absoluted-fullwidth;
    z-index: 1 !important;
  }

  &.play-video {
    .video-poster-image {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.video-poster-image {
  @include absoluted-fullwidth;
  cursor: pointer;
  z-index: 9;
  transition: all 0.3s ease 0s;
  background-image: url();

  .play-button {
    @include horizontal-vertical-align(absolute);
    width: 26.9%;
    z-index: 9;
  }
}