.form-back-wrapper {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  gap: 20px;
  margin-bottom: 40px;

  .form-back {
    margin-bottom: 0;
  }
}

.contact-btn-wrapper {
  @include flexbox();
  @include align-items(center);
  gap: 20px;
  max-width: 350px;

  p {
    @extend %fontsize14;
    margin-bottom: 0;
  }
}

.headline-with-line {
  border-bottom: 3px solid $primary-blue-color;
  margin-top: 50px;
}

.box-wrapper {
  .headline-with-line {
    margin-bottom: 30px;
    padding: 5px 0;
  }
}

.small-boxs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  margin: 37px 0;
  padding-bottom: 25px;
  border-bottom: 1px solid $brand-color-medium-gray;

  .small-boxs__item {
    border: 2px solid $secondary-color;
    border-radius: 4px;
    padding: 0 5px;
    font-family: $font-secondary;
    font-weight: $bold-weight;
    letter-spacing: 1.06px;
    font-size: 18px;
    line-height: 23px;
    cursor: pointer;
    position: relative;

    &::before {
      content: '...';
      width: 11px;
      height: 11px;
      background-color: #C91414;
      border-radius: 50%;
      position: absolute;
      top: -3px;
      right: -4px;
      color: #fff;
      text-align: center;
      font-size: 8px;
      line-height: 6px;
      font-weight: 700;
      padding-left: 1px;
    }

    &.disabled {
      &::after {
        content: '';
        position: absolute;
        top: -5px;
        right: -10px;
        bottom: -5px;
        left: -10px;
        background-color: rgba(255,255,255,0.8);
        z-index: 999;
        cursor: not-allowed;
      }
    }
    &.completed {
      &::before {
        content: '';
        background-image: url('data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2076.887%2055.552%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M34.373%2049.541L76.887%205.889%2070.739%200%2028.426%2043.458%206.078%2020.632%200%2026.585l22.488%2022.972%206.029%205.995-.091-.085.091.085%205.856-6.011z%22%2F%3E%3C%2Fsvg%3E');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 6px 8px;
        background-color: #59C156;
      }
    }
  }
}

.bank-detail-form {
  border-bottom: 1px solid $brand-color-medium-gray;
  .small-boxs{
    Input{
      width: 320px;
    }
  }
}