/****************************************************************************
  Body
 ***************************************************************************/
body {
  @extend %fontsize16;
  font-family: $font-primary;
  background-color: $brand-color-light-gray;
  color: $body-color;
}

.container {
  max-width: 1680px;
}

p {
  font-weight: $regular-weight;
  margin-bottom: 1px;

  &.small-text {
    @extend %fontsize14;
  }
}

img {
  height: auto;
  max-width: 100%;
}

figure {
  margin: 0 0 15px;
}

ul {
  list-style-type: none;
  padding: 0;
}

.lead {
  @extend %fontsize18;
}

/****************************************************************************
  Links
 ***************************************************************************/
a {
  @include transition(all, 0.2s, ease, 0s);
  color: $primary-blue-color;
  font-family: $font-primary;
  outline: none;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $primary-color;
  }

  span {
    @include transition(all, 0.2s, ease, 0s);
  }
}

strong,
b {
  font-weight: $bold-weight;
}

:-moz-selection {
  /* Code for Firefox */
  background: $brand-color;
  color: #fff;
}

::selection {
  background: $brand-color;
  color: #fff;
}

.text-right {
  text-align: right;
}

/****************************************************************************
  Headings
 ***************************************************************************/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $primary-blue-color;
  font-family: $font-secondary;
  font-weight: $bold-weight;
  text-transform: uppercase;
}

h1 {
  @extend %fontsize43;
}

h2 {
  @extend %fontsize43;
}

h3 {
  @extend %fontsize24;
}

h4 {
  @extend %fontsize21;
}

h5 {
  @extend %fontsize18;
}