.audio-react-recorder {
  canvas {
    display: none;
  }
}

.audio-box {
  background-color: $black;
  height: 81px;
  padding: 10px 15px;

  h5 {
    color: $white;
    margin-bottom: 20px;
    text-transform: capitalize;
  }

  .audio-in {
    margin: 0 15px;
    border-top: 2px solid #7d7d7d;
    flex: 1 1 auto;
  }

  .time-left {
    font-size: 10px;
    line-height: 24px;
    color: #DBDBED;
  }
}

.player {
  padding: 10px 15px;
  background-color: #212527;

  p {
    color: $brand-color-medium-gray;
    display: flex;
    justify-content: space-between;
  }

  .audio-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .controls {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .player__button {
    width: fit-content;
    margin-right: 15px;
    padding: 0;
    line-height: 15px;
    background-color: transparent;
    border: none;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;

      svg {
        color: greenyellow;
      }
    }

    svg {
      font-size: 4em;
      color: white;
    }
  }

  .voice__button {
    width: fit-content;
    margin-left: 15px;
    padding: 0;
    line-height: 15px;
    background-color: transparent;
    border: none;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;

      svg {
        color: greenyellow;
      }
    }

    svg {
      font-size: 4em;
      color: white;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;

    .controls {
      width: 100%;
      margin-top: 20px;
    }

    .bar {
      width: 90%;
    }
  }

  @media screen and (max-width: 500px) {
    .song {
      .song__title {
        font-size: 2.5em;
      }

      .song__artist {
        font-size: 0.8em;
      }
    }
  }
}

.bar {
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;

  .bar__time {
    color: white;
    font-size: 16px;
  }

  .bar__progress {
    flex: 1;
    border-radius: 2px;
    margin: 0 20px 0 0;
    height: 2px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .bar__progress__knob {
      position: relative;
      height: 18px;
      width: 18px;
      border: 1.5px solid #fff;
      border-radius: 50%;
      background-color: #D8D8D8;
    }
  }
}

.custom-audio-player {
  padding: 0;
  background-color: #212527;

  .rhap_controls-section {
    display: none;
  }

  .rhap_main-controls {
    justify-content: flex-start;
    flex: 0 0 30px;
    max-width: 30px;

    .rhap_main-controls-button {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 16px;
      height: 16px;
      margin: 0;
    }
  }

  .rhap_time {
    color: $white;
  }

  .rhap_progress-bar {
    height: 2px;

    .rhap_progress-indicator {
      height: 18px;
      width: 18px;
      border: 1.5px solid #fff;
      border-radius: 50%;
      background-color: $white;

    }

    .rhap_progress-filled {

      background-color: $white;
    }

    .rhap_download-progress {
      background-color: rgb(125, 125, 125);
    }

  }

  .rhap_volume-controls {
    flex: 0 0 40px;

    .rhap_volume-container {
      flex: 0 0 26px;

      .rhap_volume-button {
        margin: 0;
      }
    }

    .rhap_volume-bar-area {
      display: none;
    }
  }
}