.delete-voice-modal {
  .lead {
    margin-top: 10px;
  }

  .btn {
    min-width: 200px;
  }

  @include media-breakpoint-up(lg) {

    &.modal-lg,
    &.modal-xl {
      max-width: 789px;
    }
  }
}