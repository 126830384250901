.employee-details-show__content {
    .profile-box {
        margin-bottom: 30px;

        .small-text {
            margin-bottom: 5px;
            color: $brand-color-dark-gray;
            font-weight: $semibold-weight;
            text-transform: uppercase;
        }

        .lead {
            @extend %fontsize20;
        }
    }

    .profile-box__tags {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .custom-chk {
            cursor: auto;
        }

        p {
            border-color: $border-color ;
            background-color: $border-color;
            color: $black;
            padding: 5px 23px;
        }
    }

    .profile-box__rating {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }
}

.application-form {
    margin-top: 60px;

    h3 {
        margin-bottom: 40px;
    }
    
    .application-form-wrapper {
        &:not(:last-child) {
            margin-bottom: 50px;
            padding-bottom: 50px;
            border-bottom: 1px solid #9CA3AF;
        }
    }

    .job-list__item {
        border-bottom-color: $border-color;
    }
}

.info-modal {
    max-width: 696px;
}