.staff-select-modal {
  .modal-dialog {
    @include media-breakpoint-up(sm) {
      max-width: 500px;
    }

    @include media-breakpoint-up(md) {
      max-width: 700px;
    }

    @include media-breakpoint-up(lg) {
      &.modal-lg {
        max-width: calc(100% - 30px);
      }
    }

    @include media-breakpoint-up(xxl) {
      &.modal-lg {
        max-width: 1395px;
      }
    }
  }

  .modal-title {
    h3 {
      color: $brand-color-dark-gray;
    }
  }

  .modal-content {
    overflow: auto;

    @include media-breakpoint-up(lg) {
      min-height: auto;
    }
  }

  .modal-lg,
  .modal-xl {
    @include media-breakpoint-up(lg) {
      max-width: 1395px;
    }
  }

  .modal-header,
  .modal-body {
    min-width: 500px;
  }
}

.chose-staff-container {
  p {
    font-size: 12px
  }

  @include media-breakpoint-down(md) {
    gap: 20px;
  }
}

.staff-box-wrappar {
  background-color: $brand-color-light-gray;
  display: flex;
  align-items: center;
  min-height: 105px;
  padding: 27px 20px;

  @include media-breakpoint-up(md) {
    min-height: calc(100% - 20px);
  }
}

.staff-header-wrappar {
  min-height: 22px;
  margin-bottom: 6px;

  span {
    @extend %fontsize14;
    color: $secondary-color;
    text-transform: uppercase;
    font-weight: $semibold-weight;
  }


}

.applicant-lists {
  @include flexbox();
  @include align-items(center);
  @include flex-wrap(wrap);
  list-style: none;
  margin: 0;

  li {
    border-radius: 50%;
    height: 50px;
    margin: 0 6px 14px;
    position: relative;
    width: 50px;

    +li {
      margin-left: 8px;
    }

    img {
      @include absoluted-fullwidth;
      object-fit: cover;
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }

    &.available-staff {
      border: 3px solid $primary-color;
    }
  }
}

.booked-applicant {
  img {
    border: 3px solid #F4CE4E;
  }
}

.blocked-applicant {
  img {
    border: 3px solid #fe0202;
  }
}

.booked-bookingList-applicant {
  border: 3px solid #F4CE4E;
}

.blocked-bookingList-applicant {
  border: 3px solid #fe0202;
}

.staff-gender {
  position: absolute;
  right: -6px;
  top: -5px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSI+PGNpcmNsZSBjeD0iOCIgY3k9IjgiIHI9IjgiIGZpbGw9IiMwMDAiLz48ZyBzdHJva2U9IiNGRkYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIuNDYyIDEuNDYyKSI+PGNpcmNsZSBjeD0iNC43MDgiIGN5PSI3LjY0NiIgcj0iMy42Ii8+PHBhdGggZD0iTTcuNDU5IDQuOTQ3bDIuNjExLTIuNjEybS0yLjI2OC0uMTYzaDIuMzVtLjA4MiAyLjQzMWwtLjAwMS0yLjM1Ii8+PC9nPjwvZz48L3N2Zz4=');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 26px;
  width: 26px;
  height: 26px;

  &.female {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSI+PGNpcmNsZSBjeD0iNy44IiBjeT0iNy44IiByPSI3LjgiIGZpbGw9IiMwMDAiLz48ZyBzdHJva2U9IiNGRkYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUuMzcgMy43NSkiPjxjaXJjbGUgY3g9IjIuNTY1IiBjeT0iMi41NjUiIHI9IjIuNTY1Ii8+PHBhdGggZD0iTTIuNTY1IDUuNzZ2Mi44OE0xLjQ4OSA3LjQyNUgzLjc4Ii8+PC9nPjwvZz48L3N2Zz4=');
  }

  &.other {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDMxMC4yODUgMzEwLjI4NSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzEwLjI4NSAzMTAuMjg1OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8cGF0aCBkPSJNMTU1LjE0MywwLjAwMUM2OS41OTcsMC4wMDEsMCw2OS41OTcsMCwxNTUuMTQzYzAsODUuNTQ1LDY5LjU5NywxNTUuMTQyLDE1NS4xNDMsMTU1LjE0MnMxNTUuMTQzLTY5LjU5NywxNTUuMTQzLTE1NS4xNDINCglDMzEwLjI4NSw2OS41OTcsMjQwLjY4OSwwLjAwMSwxNTUuMTQzLDAuMDAxeiBNMjQ0LjE0MywxNzEuNDk4YzAsNC40MTEtMy41ODksOC04LDhoLTE2M2MtNC40MTEsMC04LTMuNTg5LTgtOHYtMzINCgljMC00LjQxMSwzLjU4OS04LDgtOGgxNjNjNC40MTEsMCw4LDMuNTg5LDgsOFYxNzEuNDk4eiIvPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=');
  }
}

.staff-select-info {
  @extend %fontsize14;
  @include flexbox();
  @include align-items(center);
  max-width: 100%;

  p {
    margin: 0;
    width: calc(100% - 24px);
    padding-left: 12px;
  }

  span {
    @include inline-block;
    border: 3px solid $primary-color;
    border-radius: 50%;
    height: 24px;
    width: 42px;
  }

  span.red-circle {
    border: 3px solid #fe0202;
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    right: 260px;
    max-width: 400px;
  }
}

.staff-book-item {
  @include flexbox();
  @include align-items(center);
  @include flex-wrap(wrap);
  gap: 20px;

  &.item--disabled {
    pointer-events: none;
    justify-content: center;
    width: 100%;

    .btn {
      opacity: 0.2;
    }
  }

  .btn {
    font-size: 11px;
    padding: 1px 9px;
    margin-left: 0;
  }
}

.staff-book-image {
  background-color: $brand-color-medium-gray;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  overflow: hidden;
}

.staff-custom-tabs {
  @include inline-flexbox();
  @include align-items(center);
  margin-left: 14px;

  .sort-type-in {
    font-size: 14px;
    line-height: 19px;
    color: $primary-blue-color;
    margin-left: 18px;
    cursor: pointer;

    .icon-in {
      display: inline-block;
      margin-left: 9px;
      width: 15px;
      height: 14px;

      svg {
        margin-top: -3px;
      }
    }
  }
}

.staff-tab__filter {
  cursor: pointer;
}

.staff-tab__map {
  cursor: pointer;
  margin-left: 12px;
}

.staff-map-block {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid $brand-color-medium-gray;
}

.checkbox-wrapper {
  border-bottom: 1px solid $border-color;
  padding: 20px 18px 20px 21px;
  margin-bottom: 20px;

  .form-group {
    margin-bottom: 18px;
  }

  @include media-breakpoint-up(xl) {
    border-bottom: 0;
    border-left: 1px solid $border-color;
    margin-bottom: 12px;
    padding-bottom: 0;
  }
}

.checkbox-title {
  @include flexbox();
  @include align-items(center);
  margin-bottom: 12px;

  span {
    @extend %fontsize14;
    @include inline-block();
    font-weight: $semibold-weight;
    margin-right: 12px;
  }

  .staff-gender {
    position: static;
    background-size: contain;
  }
}

.staff-filter-block {
  .form {
    .row:first-child {
      margin-top: 20px;
      margin-bottom: 20px;
      border-top: 1px solid $brand-color-dark-gray;
      border-bottom: 1px solid $brand-color-dark-gray;
      padding-top: 25px;
      padding-bottom: 25px;

      >div:last-child {
        .checkbox-wrapper {
          border-bottom: 0;
        }
      }
    }

    .col-xl-1 {
      @include media-breakpoint-up(xl) {
        width: 11.33333%;
      }
    }
  }

  .checkbox-title .staff-gender {
    margin-right: 0;
    min-width: 26px;
    height: 26px;
  }

  @include media-breakpoint-up(xxl) {
    .col-xxl-2 {
      width: 14%;
    }

    .col-xxl-1 {
      width: 11%;
    }
  }
}

.lang-chk {
  .form-group {
    @include inline-block;
    width: 50%;
  }
}

.staff-form-actions {
  margin-bottom: 33px;

  .btn {
    @extend %fontsize14;
    padding: 7px 11px;
  }
}
