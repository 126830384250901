.content-main {
  margin-top: 60px;
  h2 {
    padding: 0 15px;
  }
}

.headline-container {
  position: relative;
  white-space: pre-wrap;
}

.main-content-stamp {
  position: absolute;
  pointer-events: none;
  right: 4%;
  top: 130px;
  width: 150px;
}

.main-content-headline {
  text-transform: none;
}

.f-width-100 {
  width: 100% !important;
}

.padding-left-30 {
  @include media-breakpoint-up(xl) {
    padding-left: 30px;
  }
}

.sidebar-wrapper {
  position: relative;

  &::before {
    background-color: $primary-color;
    content: '';
    height: 150px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  @include media-breakpoint-up(lg) {
    &::before {
      height: 100%;
      width: 19.1%;
    }
  }

  // @include media-breakpoint-up(xl) {
  //   &::before {
  //     height: 100%;
  //     width: 363px;
  //   }
  // }
}

.content-section {
  padding: 125px 0 40px;

  ul {
    margin-bottom: 30px;
    padding-left: 30px;
    position: relative;

    li {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &::before {
        @include border-radius(50%);
        content: '';
        background-color: $secondary-color;
        position: absolute;
        left: -20px;
        top: 9px;
        height: 5px;
        width: 5px;
      }
    }
  }

  h3 {
    margin-bottom: 20px;
  }

  h1 {
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      margin-bottom: 60px;
    }
  }
}

.image-wrapper {
  margin: 60px 0;

  @include media-breakpoint-up(md) {
    margin: 80px 0;
  }

  @include media-breakpoint-up(lg) {
    margin: 120px 0;
  }
}

.decorated-link {
  font-weight: $bold-weight;
  text-decoration: underline;
}

iframe {
  z-index: -1 !important;
}

.link {
  @include transition(all, 0.3s, ease-in-out, 0s);
  color: $primary-blue-color;
  display: inline-block;
  font-weight: $semibold-weight;
  letter-spacing: normal;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $primary-color;
  }
}

.arrow-link {
  position: relative;
  padding-right: 25px;

  &::before {
    @include transition(all, 0.3s, ease-in-out, 0s);
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTkgMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iI0Y0Q0U0RSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNLjk5IDcuMDg2aDE1LjEzOEwxMC41MzEgMS40N0EuODcyLjg3MiAwIDAxMTAuNTIuMjU2YS44MjYuODI2IDAgMDExLjE4Ni0uMDExbDcuMDQzIDcuMDY5YS44NjcuODY3IDAgMDEuMDA2IDEuMjE4TDExLjcgMTUuNzQ2YS44MjYuODI2IDAgMDEtMS4xODYgMCAuODcuODcgMCAwMTAtMS4yMTJMMTYuMTIxIDguOEguOTlhLjg0OC44NDggMCAwMS0uODM5LS44NTdjMC0uNDczLjM3Ni0uODU3LjgzOS0uODU3Ii8+PC9zdmc+');
    width: 19px;
    height: 16px;
    content: '';
    position: absolute;
    right: 0;
    top: 2px;
  }

  &:hover {
    &::before {
      right: -5px;
    }
  }
}

.site-main {
  min-height: 100vh;
}

.text-semibold {
  font-weight: $semibold-weight;
}

.form-back {
  @include transition(all, 0.3s, ease-in-out, 0s);
  display: inline-block;
  width: 34px;
  height: 24px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSJjdXJyZW50Q29sb3IiIGNsYXNzPSJiaSBiaS1hcnJvdy1sZWZ0IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTUgOGEuNS41IDAgMDAtLjUtLjVIMi43MDdsMy4xNDctMy4xNDZhLjUuNSAwIDEwLS43MDgtLjcwOGwtNCA0YS41LjUgMCAwMDAgLjcwOGw0IDRhLjUuNSAwIDAwLjcwOC0uNzA4TDIuNzA3IDguNUgxNC41QS41LjUgMCAwMDE1IDh6Ii8+PC9zdmc+');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-bottom: 40px;
  cursor: pointer;

  &:hover {
    background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjNDFBOUM3IiBjbGFzcz0iYmkgYmktYXJyb3ctbGVmdCIgdmlld0JveD0iMCAwIDE2IDE2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTE1IDhhLjUuNSAwIDAwLS41LS41SDIuNzA3bDMuMTQ3LTMuMTQ2YS41LjUgMCAxMC0uNzA4LS43MDhsLTQgNGEuNS41IDAgMDAwIC43MDhsNCA0YS41LjUgMCAwMC43MDgtLjcwOEwyLjcwNyA4LjVIMTQuNUEuNS41IDAgMDAxNSA4eiIvPjwvc3ZnPg==');
  }
}

.total-amount {
  @extend %fontsize16;
  display: inline-block;
  background-color: #3bd4a1;
  border-radius: 21px;
  color: $white;
  padding: 10px 12px;
  min-width: 73px;
  text-align: center;
}

.ce-text {
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.form-profile-img-upload {
  h4 {
    color: $black;
  }

  .cr-slider-wrap {
    pointer-events: none;
  }

  .cropped-img-in {
    position: relative;
    width: 180px;
    height: 180px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .img-zoom-out {
    cursor: pointer;
    position: absolute;
    left: 20px;
    bottom: 15px;
    z-index: 11;
  }

  .img-zoom-in {
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 15px;
    z-index: 11;
  }

  .cr-boundary {
    border-radius: 0;
  }
}

.cr-boundary {
  border-radius: 50%;
}

.text-primary {
  color: $primary-blue-color !important;
}

.sigCanvas {
  background-color: #f4f4f5;
  border-color: #f4f4f5;
}

.modalImageNext,
.modalImagePrev {
  width: 16px;
  height: 20px;
  top: calc(50% - 10px);
  position: absolute;
  right: 5px;

  &:hover {
    cursor: pointer;
  }
}

.modalImagePrev {
  right: auto;
  left: 5px;
  transform: rotate(180deg);
}

.sign-contract-rte {
  > .rich-text-editor {
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    p,
    span {
      color: $black;
      text-transform: none;
    }
  }
}

.profile-headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status-label {
  @extend %fontsize14;
  margin-bottom: 20px;
  padding: 11px 50px 11px 23px;
  border-radius: 20px;
  background-color: #3bd4a1;
  color: #fff;
  font-weight: $bold-weight;
  position: relative;

  &::after {
    position: absolute;
    top: 8px;
    right: 15px;
    content: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjYgMjYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTYuMDY0IDE0LjI3YS43Ny43NyAwIDEwMC0xLjU0aC0yLjQyVjguNjk2YS43Ny43NyAwIDEwLTEuNTQgMFYxMy41YzAgLjQyNy4zNDQuNzcuNzcuNzdoMy4xOXptLjcyNCA4Ljc3N2EuMjU4LjI1OCAwIDAxLS4yNTcuMjU3SDkuMjEyYS4yNi4yNiAwIDAxLS4yNTYtLjI1N3YtMi4wMzlhOC42MjQgOC42MjQgMCAwMDQuMDQ3IDEuMDEyIDguNTIgOC41MiAwIDAwMy43ODUtLjg4OXYxLjkxNnpNNS44NzQgMTMuMzVjMC0zLjkzIDMuMi03LjEyNCA3LjEyOS03LjEyNGE3LjEzIDcuMTMgMCAwMTcuMTIzIDcuMTI0YzAgMy45MjgtMy4xOTUgNy4xMjgtNy4xMjMgNy4xMjgtMy45MyAwLTcuMTI5LTMuMi03LjEyOS03LjEyOHptMy4wODItOS44MmMwLS4xNDQuMTE4LS4yNTcuMjU2LS4yNTdoNy4zMTljLjE0NCAwIC4yNTcuMTEzLjI1Ny4yNTd2Mi4wNGE4LjU4NCA4LjU4NCAwIDAwLTMuNzg1LS44ODQgOC42MiA4LjYyIDAgMDAtNC4wNDcgMS4wMDdWMy41MzF6bTkuMzYyIDIuOTg5Yy4wMDUtLjA0MS4wMS0uMDgyLjAxLS4xMjhWMy41M2ExLjggMS44IDAgMDAtMS43OTctMS43OThIOS4yMTJBMS44IDEuOCAwIDAwNy40MTUgMy41M3YzLjA1YzAgLjA0Ny4wMS4wOTMuMDE1LjE0YTguNjUgOC42NSAwIDAwLTMuMDk3IDYuNjMgOC42NDcgOC42NDcgMCAwMDMuMDgyIDYuNjE0djMuMDgyYTEuOCAxLjggMCAwMDEuNzk3IDEuNzk3aDcuMzE5YTEuOCAxLjggMCAwMDEuNzk3LTEuNzk3VjIwLjE3YTguNjQ2IDguNjQ2IDAgMDAzLjMzOS02LjgyIDguNjUyIDguNjUyIDAgMDAtMy4zNDktNi44MzF6Ii8+PC9zdmc+);
    width: 26px;
    height: 26px;
  }

  &.approved {
    &::after {
      content: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjYgMjYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjRkZGIiB0cmFuc2Zvcm09InJvdGF0ZSg0NSAuOTEgMTkuNTYyKSI+PHJlY3Qgd2lkdGg9IjEuNjIxIiBoZWlnaHQ9IjExLjM0NCIgeD0iNS42MjYiIHk9Ii4yMjQiIHJ4PSIuODEiLz48cmVjdCB3aWR0aD0iMS42MjEiIGhlaWdodD0iNi40ODIiIHg9IjIuOTUyIiB5PSI3LjQ4MSIgcng9Ii44MSIgdHJhbnNmb3JtPSJyb3RhdGUoOTAgMy43NjIgMTAuNzIyKSIvPjwvZz48Y2lyY2xlIGN4PSIxMyIgY3k9IjEzIiByPSIxMC40IiBzdHJva2U9IiNGRkYiIHN0cm9rZS13aWR0aD0iMS43MzMiLz48L2c+PC9zdmc+);
    }
  }
}

@include media-breakpoint-up(lg) {
  .content-main {
    margin-top: 160px;

    h2 {
      padding-left: 30px;
    }
  }

  .content-section {
    padding: 170px 0 60px;
  }
}

@include media-breakpoint-up(xl) {
  .content-main {
    margin-top: 230px;

    h2 {
      padding-left: 60px;
    }
  }

  .content-section {
    padding: 180px 0 78px;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 640px) {
  .ReactModalPortal {
    .ReactModal__Content {
      .modalPreviewImage {
        height: auto !important;
        width: auto !important;
      }
    }
  }

  .buttonalign {
    margin: 2rem 0 !important;
    text-align: center !important;
  }
}

@media screen and (max-width: 768px) {
  .ReactModalPortal {
    .ReactModal__Content {
      .modalPreviewImage {
        height: auto !important;
        width: auto !important;
      }
    }
  }

  .main-content-stamp {
    top: 64%;
    width: 108px;
  }

  .buttonalign {
    margin: 2rem 0 !important;
    text-align: center !important;
  }
}

@media screen and (max-width: 992px) {
  .ReactModalPortal {
    .ReactModal__Content {
      .modalPreviewImage {
        height: auto !important;
        width: auto !important;
      }
    }
  }

  .main-content-stamp {
    top: 86%;
    width: 108px;
  }
}

@media screen and (max-width: 1260px) {
  .ReactModalPortal {
    .ReactModal__Content {
      .modalPreviewImage {
        height: auto !important;
        width: auto !important;
      }
    }
  }
}

#contract_final {
  a,
  ul,
  li,
  p,
  div,
  img {
    page-break-before: auto !important;
    page-break-after: auto !important;
    page-break-inside: avoid !important;
    position: relative;
    break-inside: avoid;
  }
}

.ql-editor {
  height: 220px;
  border: 1px solid lightgrey;
}
