.box-wrapper {
  background-color: #fff;
  padding: 30px 15px;

  +.box-wrapper {
    margin-top: 35px;
  }

  @include media-breakpoint-up(lg) {
    @include border-radius(2px);
    @include box-shadow(0 30px 80px rgba(0, 0, 0, 0.1));
    background-color: #fff;
    padding: 80px 100px;

    &.fixed-box {
      min-height: 650px;
    }

    +.box-wrapper {
      margin-top: 67px;
    }
  }

  // For toogle switch and other filter in job wall page
  .toggle-switch-filters{
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    font-size: 14px;
    margin-bottom: 20px;
    .toggle-switch-region{
      margin-right: 25px;
      align-items: center;
      display: flex;
      .filterValue p{
        margin-bottom: 0;
      }
    }
    .toggle-switch {
      position: relative;
      display: inline-block;
      width: 43px;
      height: 22px;
      margin-right: 15px;
    }
    .toggle-switch input[type="checkbox"] {
      display: none;
    }
    .toggle-switch .switch {
      position: absolute;
      cursor: pointer;
      background-color: #D8D8D8;
      border-radius: 25px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 0.2s ease;
    }
    .toggle-switch .switch::before {
      position: absolute;
      content: "";
      left: 2px;
      top: 1px;
      width: 21px;
      height: 20px;
      background-color: #D8D8D8;
      border: 1px solid white;
      border-radius: 50%;
      transition: transform 0.3s ease;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch::before {
      transform: translateX(19px);
      background-color: $primary-blue-color;
      border: 1px solid white;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch {
      background-color: $primary-blue-color;
    }
  }
  .eventJobs{
    clear: both;
  }
}

.form-box-wrapper {
  @include media-breakpoint-up(lg) {
    padding: 60px 100px;
  }
}

.filterValue {
  width: 100%;
}

.switch {
  width: 44px;
}

@media (max-width: 768px) {

  .toggle-switch-filters {
    flex-direction: column;
    justify-content: left;
  }

  .toggle-switch-region{
    padding-top: 7px;
  }
}


