.page-footer {
  background-color: $secondary-color;
  color: $white;
  padding: 32px 0;

  .container {
    max-width: 95%;
  }
}

.footer-contact__links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.footer-contact__link {
  align-items: center;
  color: $white;
  display: inline-flex;
  margin: 10px 10px 10px 0;

  .footer-contact__text {
    max-width: calc(100% - 35px);
    padding: 0 15px;
  }
}

.footer-contact__icon {
  background-color: $primary-blue-color;
  border-radius: 50%;
  display: inline-block;
  height: 35px;
  width: 35px;
}

.footer-block {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
}

.footer-logo-teaser {
  display: flex;
}

.footer-logo-teaser__logo {
  .brand-logo {
    margin-top: 0;
    max-width: 87px;
    display: block;
    background-color: $primary-color;
    padding: 17px 5px;
  }

  p {
    margin-bottom: 0;
  }
}

.footer-logo-teaser__content {
  // max-width: calc(100% - 70px);
  // padding: 0 20px;
  width: 50%;
  padding-left: 20px;
}

.footer-title {
  color: $white;
  font-family: $font-primary;
  font-weight: $regular-weight;
  margin-bottom: 15px;
  text-transform: inherit;
}

.footer-block-right {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  height: 100%;

  .footer-block-right__link {
    @extend %fontsize14;
    color: $white;
    margin: 10px 20px 10px 0;

    &:hover {
      color: $primary-color;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.formsize {
  width: 70px !important;
}

@include media-breakpoint-up(md) {
  .footer-block {
    flex-wrap: nowrap;
    margin-bottom: 0;
  }
}

@include media-breakpoint-up(lg) {
  .footer-logo-teaser__logo {
    .brand-logo {
      height: calc(100% + 32px);
    }
  }

  .footer-block {
    >div {
      max-width: 50%;
      flex: 0 0 50%;
    }
  }
}

@include media-breakpoint-up(xl) {
  .footer-block {
    >div {
      max-width: none;
      flex: 0 0 auto;
    }
  }
}