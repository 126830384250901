.image-text-teaser {
  @include flexbox();
  @include align-items(center);
  @include flex-wrap(wrap);
  background-color: $white;
  border-top: 8px solid $primary-color;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  overflow: hidden;
  padding-bottom: 20px;
  position: relative;
  margin-bottom: 30px;
  min-height: calc(100% - 30px)
}

.image-text-teaser__image {
  width: 100%;
  text-align: center;
  max-width: 70px;
}

.image-text-teaser__content {
  padding: 12px 15px;
  max-width: calc(100% - 70px);
}

.image-text-teaser__text {
  @extend %fontsize14;
  margin-bottom: 25px;
}

.image-text-teaser__title {
  margin-bottom: 32px;
  text-transform: uppercase;
}

@include media-breakpoint-up(xl) {
  .image-text-teaser__image {
    max-width: 124px;
  }

  .image-text-teaser__content {
    padding: 16px 20px;
    max-width: calc(100% - 124px);
  }
}