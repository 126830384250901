.add-new-request {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 50px;

  .cart-icon {
    position: relative;
    cursor: pointer;
    margin-right: 48px;
    .cart_box {
      position: absolute;
      width: 22px;
      height: 22px;
      left: 18px;
      top: -8px;
    }
    .cart_list_count {
      padding: 2.6px 7.3px;
      background: #f1cc4d;
      border-radius: 50%;
      font-size: 12.83px;
      line-height: 18px;
      font-weight: 600;
    }
  }
}