//****************************************************************************
//  Bootsrap variables overrides
//***************************************************************************/
$font-family-sans-serif: 'Open Sans', Arial, sans-serif;
$font-primary: $font-family-sans-serif;
$font-secondary: 'OpenSansCondensed', $font-primary;
$font-abril: 'Abril Fatface', $font-primary;
$font-montserrat: 'Montserrat', $font-primary;
$btn-box-shadow: none;

//****************************************************************************
//  Custom variables
//***************************************************************************/
$primary-color: #f4ce4e;
$secondary-color: #000000;
$primary-blue-color: #41a9c7;
$primary-blue-light-color: #ecf6f6;
$primary-red-color: #c91414;
$text-color: #797986;
$text-muted-color: #52525B;
$brand-color: $primary-color;

$body-color: $secondary-color;
$brand-color-gray: #f4f4f5; //Gray
$brand-color-light-gray: #fafafa; //Gray
$brand-color-medium-gray: #d1d5db; //Gray
$brand-color-dark-gray: #a1a1aa; //Gray Dark
$brand-color-extra-dark-gray: #71717a; //Gray Exrta Dark
$border-color: #e4e4e7; //Gray
$popup-bg: #3f3f46; //Pop-up Background Color
$table-hover-bg: $brand-color-gray;

//****************************************************************************
//  Font Weight
//***************************************************************************/
$regular-weight: 400;
$bold-weight: 700;
$semibold-weight: 600;
$extra-bold-weight: 800;