.form-add-job {
  margin: 40px 15px;
  // z-index: 9999;
  position: relative;

  .small {
    margin-bottom: 50px;
    font-weight: $semibold-weight;
  }

  .large {
    margin-bottom: 50px;
    font-weight: $semibold-weight;
    font-size: 18px
  }
}

.add-job-form {
  .form-bottom {
    @include justify-content(flex-end);
    padding-top: 20px;
    border-top: 1px solid $border-color;
  }

  .form-group {
    width: 100%;
    margin-bottom: 20px;
    @extend %fontsize14;

    &.first-form-group {
      max-width: 92px;
    }

    &.date-picker-group {
      min-width: 119px;
    }
  }

  .delete-btn {
    display: block;
    text-align: right;
    padding: 13px 12px;

    svg {
      width: 19px;
      height: 22px;

      path {
        @include transition(all, 0.3s, ease-in-out, 0s);
      }
    }

    &:hover {
      svg {
        path {
          fill: $primary-color;
        }
      }
    }
  }
}

.add-another-job {
  text-align: right;
  margin-bottom: 40px;
  margin-top: 20px;
}

.add-job-container {
  padding-right: 90px;
  position: relative;

  .delete-fields {
    position: absolute;
    right: 15px;
    top: 0;
  }

  +.add-job-container {
    margin-top: 50px;
  }
}

.selected-job-tax {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid $brand-color-medium-gray;

  p {
    margin-bottom: 0;
  }
}

.selected-job-tax-value {
  display: flex;
  align-items: center;
}

.sample-staff-block {
  margin-top: 45px;

  .headline {
    color: $secondary-color;
    font-family: $font-primary;
    font-weight: $semibold-weight;
    margin-bottom: 35px;
  }

  .small-text {
    font-weight: $semibold-weight;
  }

  .applicant-lists {
    margin-bottom: 30px;
  }
}

.autocomplete-dropdown-container {
  position: absolute;
  color: $black;
  font-size: 16px;
  z-index: 1000;
  box-shadow: none;
  background-color: #f4f4f5;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-radius: 8px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  overflow: hidden;


  >div {
    padding: 13px 10px;

    &.suggestion-item--active {
      color: $primary-blue-color
    }
  }
}

.location-search-input-wraper {
  .form-errors {
    position: absolute;
  }
}

.autocomplete-wrapper {
  position: relative;

  &.f-width-100 div {
    display: block !important;
    width: 100% !important;
  }

  .autocomplete-menu,
  .suggestion-item--active {
    background-color: $white;
    z-index: 9;
    padding: 10px 0;
    border-radius: 8px;
    box-shadow: 0 3px 22px rgba(0, 0, 0, 0.1);
    position: absolute;
    max-height: 205px;
    overflow-y: auto;
    left: 0 !important;
    top: 100% !important;
    width: 100% !important;

    &::-webkit-scrollbar {
      width: 10px;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #d8d8d8;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $brand-color-dark-gray;
      border-radius: 4px;
    }

    >div {
      padding: 6px 10px;

      &:hover {
        background-color: $brand-color-gray;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .form-add-job {
    padding: 50px;
    left: -30px;
    margin: 40px 0;
    min-height: auto;
  }

  .add-job-container {
    padding-right: 73px;

    .delete-fields {
      right: 0;
    }
  }

  .selected-job-tax {
    flex-wrap: nowrap;
  }
}

@include media-breakpoint-up(xl) {
  .form-add-job {
    left: -60px;
  }

  .add-job-form {
    .form-group {
      min-width: auto;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .add-job-container {
    +.add-job-container {
      margin-top: 0;

      .form-label {
        display: none;
      }
    }
  }
}
