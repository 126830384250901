/*******************************************************************************
* Profile
*******************************************************************************/
.profile-pic {
  // background-color: $brand-color-dark-gray;
  margin-bottom: 30px;
  position: relative;
  text-align: center;

  .profile-img-in {
    padding-bottom: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    &.plus-icon {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdGggaWQ9ImEiIGQ9Ik01MCAyMy4zMzNhMy4zMzMgMy4zMzMgMCAwMTMuMzMzIDMuMzM0djIwaDIwYTMuMzMzIDMuMzMzIDAgMTEwIDYuNjY2aC0yMHYyMGEzLjMzMyAzLjMzMyAwIDExLTYuNjY2IDB2LTIwaC0yMGEzLjMzMyAzLjMzMyAwIDExMC02LjY2NmgyMHYtMjBBMy4zMzMgMy4zMzMgMCAwMTUwIDIzLjMzM3oiLz48L2RlZnM+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cmVjdCB3aWR0aD0iOTMuMzMzIiBoZWlnaHQ9IjkzLjMzMyIgeD0iMy4zMzMiIHk9IjMuMzMzIiBzdHJva2U9IiNGRkYiIHN0cm9rZS13aWR0aD0iNi42NjciIHJ4PSI0Ni42NjciLz48bWFzayBpZD0iYiIgZmlsbD0iI2ZmZiI+PHVzZSB4bGluazpocmVmPSIjYSIvPjwvbWFzaz48dXNlIGZpbGw9IiMwMDAiIGZpbGwtcnVsZT0ibm9uemVybyIgeGxpbms6aHJlZj0iI2EiLz48ZyBmaWxsPSIjRkZGIiBtYXNrPSJ1cmwoI2IpIj48cGF0aCBkPSJNMCAwaDEwMHYxMDBIMHoiLz48L2c+PC9nPjwvc3ZnPg==');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 20px;
    }
  
    &.staff-profile-image--big {
      width: 100%;
      height: 200px;
  
      &.plus-icon {
        background-size: 100px;
      }
    }
  }

  .btn {
    @include transition(all, 0.3s, ease-in-out, 0s);
    padding: 11px 15px 9px;
    opacity: 0;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 190px;
    transform: translate(-50%, -50%);
    width: 100%;

    @include media-breakpoint-up(lg) {
      max-width: 150px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 190px;
    }
  }

  &::before {
    @include transition(all, 0.3s, ease-in-out, 0s);
    content: '';
    // background-color: $brand-color-dark-gray;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }

  &:hover {
    &::before {
      opacity: 0.75;
    }

    .btn {
      opacity: 1;
    }
  }
}

.form-image-upload {
  @include media-breakpoint-up(md) {
    .file-upload-btn-wrapper {
      .file-upload-content {
        padding: 50px 30px;
      }
    }

    .file-upload-icon {
      height: 140px;
      width: 140px;
      background-size: 140px 140px;
      margin: 0 auto 10px;
    }
  }
}