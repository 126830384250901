.table {
  thead {
    th {
      @extend %fontsize14;
      border-bottom: 0;
      padding: 19px 20px;
      text-transform: uppercase;
      font-weight: $semibold-weight;
      vertical-align: middle;
      white-space: nowrap;
    }
  }

  tbody {
    &:not(:first-child) {
      border-top: 1px solid $black;
    }

    td {
      @extend %fontsize16;
      border-bottom: 1px solid $brand-color-dark-gray;
      padding: 19px 18px;
      font-weight: $bold-weight;
      vertical-align: middle;
      height: 81px;

      span {
        display: inline-block;
        vertical-align: middle;
      }

      .detail-link {
        color: $primary-blue-color;
        cursor: pointer;
        text-decoration: underline;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: $primary-color;
        }
      }

      &.time-report-status,
      &.jobs-contract {
        span {
          @extend %fontsize14;
          background-color: rgba($color: #59C156, $alpha: 0.24);
          border-radius: 20px;
          font-weight: $semibold-weight;
          padding: 11px 30px;

          &:empty {
            display: none;
          }

          &:not(.in-prepare, .event-ready, .confirm-need, .open-amount) {
            position: relative;
            padding: 11px 25px 10px 35px;

            &::before {
              content: '';
              background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjNTlDMTU2IiB2aWV3Qm94PSIwIDAgMzAgMzAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTI2Ljk4IDUuOTlhMSAxIDAgMDAtLjY4Ny4zMDNMMTEgMjEuNTg2bC02LjI5My02LjI5M2ExIDEgMCAxMC0xLjQxNCAxLjQxNGw3IDdhMSAxIDAgMDAxLjQxNCAwbDE2LTE2YTEgMSAwIDAwLS43MjctMS43MTd6Ii8+PC9zdmc+');
              background-position: left center;
              background-size: cover;
              background-repeat: no-repeat;
              height: 15px;
              width: 19px;
              position: absolute;
              left: 10px;
              top: 13px;
            }
          }

          &.in-prepare {
            background-color: rgba($color: #c91414, $alpha: 0.24);
          }

          &.event-ready {
            background-color: rgba($color: #76a7df, $alpha: 0.24);
          }

          &.confirm-need, &.open-amount {
            background-color: rgba($color: #ede210, $alpha: 0.24);
          }


          &.confirmed {
            background-color: rgba($color: #1cb114, $alpha: 0.24);
          }
        }
      }

      &.events-name {
        max-width: 175px;
      }
    }

    .parent-tab>td {
      // white-space: nowrap;
      max-width: 220px;
      min-width: 130px;
      word-break: break-word;

      &:last-child {
        text-align: right;
      }
    }
  }

  &.remove-space {
    thead {
      th {
        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          text-align: right;
          padding-right: 0;
        }
      }
    }

    tbody {
      td {
        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          text-align: right;
          padding-right: 0;
        }
      }
    }
  }

  &.jab-wall-table {
    tbody {
      tr:hover {
        cursor: pointer;
        background-color: #daeff5;
        color: #41a9c7;
      }

      td {
        @extend %fontsize14;
        max-width: 260px;
      }
    }
  }

  &.event-table {
    .time-report-status-head {
      min-width: 250px;
    }

    .event-name-head {
      min-width: 145px;
    }
  }

  &.request-payment-table {
    tbody {
      tr:last-child {
        td {
          border-bottom: 1px solid #000;
        }
      }

      td {
        font-weight: $regular-weight;
        @extend %fontsize18;

        &:first-child {
          min-width: auto;
        }

        &:last-child {
          min-width: 230px;
        }
      }
    }
  }

  &.show-address-table {
    thead {
      th {
        &:first-child {
          padding-left: 0;
        }
      }
    }

    tbody {
      &:not(:first-child) {
        border-top: 0;
      }

      td {
        font-weight: $regular-weight;
        border-bottom-color: $black;

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }

  &.job-details-table {
    margin-bottom: 40px;

    thead {
      th {
        &:last-child {
          min-width: 250px;
        }
      }
    }

    tbody {
      td {
        font-weight: $regular-weight;
        @extend %fontsize14;

        &:first-child {
          max-width: 75px;
        }
      }

      &:not(:first-child) {
        border-top: 0;
      }
    }
  }

  &.request-list {
    tbody td {
      &.datepicker-td {
        .react-datepicker-wrapper {
          transform: translateX(0px);
          // max-width: 110px;
          opacity: 0;
          cursor: pointer;

          .form-control {
            cursor: pointer;
          }
        }
      }

      &.jobs-event-name {
        max-width: 340px;
        word-break: break-word;
      }

      span {
        max-width: 340px;
      }
    }
  }
}

.sort-arrow {
  width: 14px;
  height: 12px;
  display: inline-block;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTIgMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iIzcxNzE3QSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNS4zMTUuNzN2MTEuMTU0TDEuMTAyIDcuNzZhLjY2Mi42NjIgMCAwMC0uOTEtLjAwOS42MDIuNjAyIDAgMDAtLjAwOC44NzRsNS4zMDEgNS4xOWMuMTIuMTE4LjI4NS4xODUuNDU2LjE4NWEuNjU3LjY1NyAwIDAwLjQ1OC0uMThsNS40MS01LjJhLjYwNC42MDQgMCAwMDAtLjg3My42NjEuNjYxIDAgMDAtLjkwOCAwTDYuNiAxMS44OFYuNzI5YS42My42MyAwIDAwLS42NDQtLjYxNy42My42MyAwIDAwLS42NDIuNjE3Ii8+PC9zdmc+');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px 12px;
  margin-left: 5px;

  &.sort-arrow-accending {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTIgMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iIzQxQTlDNyIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNS4zMTUuNzN2MTEuMTU0TDEuMTAyIDcuNzZhLjY2Mi42NjIgMCAwMC0uOTEtLjAwOS42MDIuNjAyIDAgMDAtLjAwOC44NzRsNS4zMDEgNS4xOWMuMTIuMTE4LjI4NS4xODUuNDU2LjE4NWEuNjU3LjY1NyAwIDAwLjQ1OC0uMThsNS40MS01LjJhLjYwNC42MDQgMCAwMDAtLjg3My42NjEuNjYxIDAgMDAtLjkwOCAwTDYuNiAxMS44OFYuNzI5YS42My42MyAwIDAwLS42NDQtLjYxNy42My42MyAwIDAwLS42NDIuNjE3Ii8+PC9zdmc+');
  }

  &.sort-arrow-descending {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTIgMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iIzQxQTlDNyIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNS4zMTUuNzN2MTEuMTU0TDEuMTAyIDcuNzZhLjY2Mi42NjIgMCAwMC0uOTEtLjAwOS42MDIuNjAyIDAgMDAtLjAwOC44NzRsNS4zMDEgNS4xOWMuMTIuMTE4LjI4NS4xODUuNDU2LjE4NWEuNjU3LjY1NyAwIDAwLjQ1OC0uMThsNS40MS01LjJhLjYwNC42MDQgMCAwMDAtLjg3My42NjEuNjYxIDAgMDAtLjkwOCAwTDYuNiAxMS44OFYuNzI5YS42My42MyAwIDAwLS42NDQtLjYxNy42My42MyAwIDAwLS42NDIuNjE3Ii8+PC9zdmc+');
    transform: rotate(180deg);
  }
}

.edit-icon {
  @include transition(all, 0.3s, ease-in-out, 0s);
  width: 18px;
  height: 18px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTggMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iIzQxQTlDNyIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMS42NzkgMTMuOTQzbDkuMzMzLTkuMzU1IDIuMzk0IDIuMzk3LTkuMzQ0IDkuMzM5SDEuNjh2LTIuMzgxem0xMS44OS0xMS45MmwyLjQwNiAyLjQwMy0xLjM3NyAxLjM3NS0yLjQtMi4zOTggMS4zNy0xLjM4ek0uODM5IDE4aDMuNTdhLjg0Ljg0IDAgMDAuNTkzLS4yNDZMMTcuNzU0IDUuMDE4YS44MzcuODM3IDAgMDAwLTEuMTg1TDE0LjE2Mi4yNDdhLjgzOS44MzkgMCAwMC0xLjE4NyAwTC4yNDYgMTMuMDA0YS44NTcuODU3IDAgMDAtLjI0Ni41OTJ2My41NjZjMCAuNDY0LjM3NS44MzguODQuODM4eiIvPjwvc3ZnPg==');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px;
  margin-left: 5px;
  cursor: pointer;

  &:hover {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTggMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iI0Y0Q0U0RSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMS42NzkgMTMuOTQzbDkuMzMzLTkuMzU1IDIuMzk0IDIuMzk3LTkuMzQ0IDkuMzM5SDEuNjh2LTIuMzgxem0xMS44OS0xMS45MmwyLjQwNiAyLjQwMy0xLjM3NyAxLjM3NS0yLjQtMi4zOTggMS4zNy0xLjM4ek0uODM5IDE4aDMuNTdhLjg0Ljg0IDAgMDAuNTkzLS4yNDZMMTcuNzU0IDUuMDE4YS44MzcuODM3IDAgMDAwLTEuMTg1TDE0LjE2Mi4yNDdhLjgzOS44MzkgMCAwMC0xLjE4NyAwTC4yNDYgMTMuMDA0YS44NTcuODU3IDAgMDAtLjI0Ni41OTJ2My41NjZjMCAuNDY0LjM3NS44MzguODQuODM4eiIvPjwvc3ZnPg==');
  }
}

.time-report-status-head,
.time-report-status,
.jobs-contract {
  text-align: right;
}

.table tbody td.jobs-contract span:not(.in-prepare, .event-ready, .confirm-need) {
  padding: 11px 30px;

  &::before {
    content: none;
  }
}

.table-layout-fixed {
  @include media-breakpoint-up(lg) {
    table-layout: fixed;
  }
}
